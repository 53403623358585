import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBIcon, MDBCardBody, MDBBadge, MDBInput, MDBBtn } from 'mdbreact';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import SelectInput from 'components/shared/SelectInput';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
import usePrevious from 'hooks/usePrevious';
import useInterval from 'hooks/useInterval';
import MonitorViajesTabla from './MonitorViajesTabla';
import { mapOptionsToViewModel } from 'utils';
// Mis Types
import { Filters } from 'typings/Tablas';
import { Option } from 'typings/General';
import apiErrorHandler from 'services/apiErrorHandler.service';

export interface MonitorViajesListaProps {
   idZona: number;
   onVerRuta: (viajeId: number | null, solicitudId: number | null) => Promise<void>;
}

const MonitorViajesLista: React.FC<MonitorViajesListaProps> = ({ idZona, onVerRuta }) => {
   const [viajes, setViajes] = useState<any[]>([]);
   const [tabValue, setTabValue] = useState<number>(0);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [estatusOptions, setEstatusOptions] = useState<Option[]>([]);
   const [filters, setFilters] = useState<Filters>({
      estatus: null
   });
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<any>({
      totalSize: 10,
      sizePerPage: 10
   });
   const [page, setPage] = useState(1);

   const actionsFormatter = (cell, row) => {
      return (
         <Fragment>
            <Tooltip title='Ver chofer' placement='top'>
               <Link className='btn btn-info btn-sm my-0 btn-action' to={`/r/choferes/${row.idChofer}/detalles`}>
                  <MDBIcon size='2x' icon='car-alt' />
               </Link>
            </Tooltip>
            <Tooltip title='Ver ruta' placement='top'>
               <MDBBtn color={'mdb-color'} className='btn btn-sm my-0 btn-action' onClick={() => onVerRuta(row.idViaje, null)}>
                  <MDBIcon size='2x' icon='route' />
               </MDBBtn>
            </Tooltip>
            {tabValue === 0 && row.estatus && row.estatus.cancelado === false && (
               <Tooltip title='Cancelar' placement='top'>
                  <MDBBtn
                     color={'danger'}
                     className='btn btn-sm my-0 ml-0 btn-action'
                     onClick={() => handleCancelViaje(row.idViaje)}>
                     <MDBIcon size='2x' icon='times-circle' />
                  </MDBBtn>
               </Tooltip>
            )}
            <Link className='btn btn-primary btn-sm detalles-font-size text-capitalize' to={`/r/viajes/${row.idViaje}/detalles`}>
               Detalles
            </Link>
         </Fragment>
      );
   };

   // TODO: pendiente los colores de las etiquetas
   function estatusBadgeFormatter(estatus) {
      if (estatus && estatus.cancelado) {
         return <MDBBadge color='danger'>Cancelado</MDBBadge>;
      }
      switch (estatus && estatus.idEstatusViaje) {
         case 1:
            return <MDBBadge color='info'>{estatus.nombre}</MDBBadge>;
         case 2:
            return <MDBBadge color='primary'>{estatus.nombre}</MDBBadge>;
         case 3:
            return <MDBBadge color='secondary'>{estatus.nombre}</MDBBadge>;
         case 4:
            return <MDBBadge color='success'>{estatus.nombre}</MDBBadge>;
         case 5:
            return <MDBBadge color='warning'>{estatus.nombre}</MDBBadge>;
         case 6:
            return <MDBBadge color='light'>{estatus.nombre}</MDBBadge>;
         case 7:
            return <MDBBadge color='default'>{estatus.nombre}</MDBBadge>;
      }
   }

   const [columns, setColumns] = useState<any[]>([
      {
         dataField: 'idViaje',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'estatus',
         text: 'Estatus',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: estatusBadgeFormatter
      },
      {
         dataField: 'nombreChofer',
         text: 'Chofer',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombreCliente',
         text: 'Cliente',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'telefonoCliente',
         text: 'Teléfono Cliente',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'horaInicio',
         text: 'Hora de inicio',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'opciones',
         text: 'Opciones',
         isDummyField: true,
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: actionsFormatter
      }
   ]);

   // Obtener los datos de los viajes
   useEffect(() => {
      setColumns(getTableColumns());

      switch (tabValue) {
         case 0: // En curso
            fetchViajes();
            fetchEstatusViajes();
            break;
         case 1: // Terminados/Finalizados
            fetchViajes(6);
            break;
         case 2: // Cancelados
            fetchViajes(3);
            break;
      }

      setPage(1);
      // eslint-disable-next-line
   }, [tabValue]);

   const prevTabValue = usePrevious(tabValue);
   useEffect(() => {
      if (prevTabValue === tabValue) {
         switch (tabValue) {
            case 0:
               fetchViajes();
               break;
            case 1:
               fetchViajes(6);
               break;
            case 2:
               fetchViajes(3);
               break;
         }
      }
      // eslint-disable-next-line
   }, [debouncedSearch, page, filters.estatus, idZona]);

   const fetchViajes = async (estatus = 0) => {
      const { sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         let params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            ...(idZona && { idZona }),
            hoy: true,
            order: 'desc',
            limit,
            page
         };

         if (estatus === 0 && !filters.estatus) {
            params['enCurso'] = true;
         } else if (estatus === 0 && filters.estatus) {
            params['idEstadoViaje'] = filters.estatus;
            params['enCurso'] = true;
         } else if (estatus === 6) {
            params['idEstadoViaje'] = estatus;
         } else if (estatus === 3) {
            params['cancelado'] = 1;
         }

         const { rows: viajesList, count: totalSize }: any = await http.get('viajes', { params });
         setViajes(mapViajesToViewModel(viajesList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         if ((error.status && error.status !== 500) || error.type) {
            toast.error(
               <ToastMessage type={'error'}>Ocurrió un error al cargar la lista de viajes, recargue la página.</ToastMessage>
            );
         }
      }
   };

   const fetchEstatusViajes = async () => {
      try {
         const { rows }: any = await http.get('catalogos/EstatusViaje');
         setEstatusOptions(mapOptionsToViewModel(rows).filter((estatus: any) => estatus.value !== 6));
      } catch (error) {}
   };

   const mapViajesToViewModel = (viajes: any[]): any[] => {
      // eslint-disable-next-line
      switch (tabValue) {
         case 0:
            return viajes.map(viaje => {
               return {
                  idViaje: viaje.idViaje,
                  estatus: {
                     ...viaje.estatusViaje,
                     cancelado: viaje.cancelado
                  },
                  nombreChofer: `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`,
                  nombreCliente: `${viaje.cliente.nombre}`,
                  telefonoCliente: viaje.cliente.telefono ? `${viaje.cliente.telefono.substring(2)}` : 'N/A',
                  horaInicio: viaje.startTripTime ? moment(viaje.startTripTime).format('HH:mm A') : '00:00',
                  idChofer: viaje.chofer.idChofer,
                  polilinea: viaje.polilineaViaje
               };
            });
         case 1:
            return viajes.map(viaje => {
               return {
                  idViaje: viaje.idViaje,
                  nombreChofer: `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`,
                  nombreCliente: `${viaje.cliente.nombre}`,
                  telefonoCliente: viaje.cliente.telefono ? `${viaje.cliente.telefono.substring(2)}` : 'N/A',
                  horaTerminacion: viaje.dropTime ? moment(viaje.dropTime).format('HH:mm A') : '00:00',
                  monto: getCosto(viaje),
                  idChofer: viaje.chofer.idChofer,
                  polilinea: viaje.polilineaViaje
               };
            });
         case 2:
            return viajes.map(viaje => {
               return {
                  idViaje: viaje.idViaje,
                  quienCancelo: viaje.cancelacion ? viaje.cancelacion.tipoCancelacion.nombre : 'N/A', // NOTE: no tengo idea de porque pero el backend me envia la cancelacion como null sin ningun dato
                  // dondeCancelo: viaje.cancelacion ? viaje.cancelacion.tipoCancelacion.idTipoCancelacion : -1, // NOTE: no tengo idea de porque pero el backend me envia la cancelacion como null sin ningun dato
                  nombreChofer: `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`,
                  nombreCliente: `${viaje.cliente.nombre}`,
                  telefonoCliente: viaje.cliente.telefono ? `${viaje.cliente.telefono.substring(2)}` : 'N/A',
                  horaCancelacion: viaje.cancelacion ? moment(viaje.cancelacion.createdAt).format('HH:mm A') : '00:00', // NOTE: no tengo idea de porque pero el backend me envia la cancelacion como null sin ningun dato
                  idChofer: viaje.chofer.idChofer,
                  polilinea: viaje.polilineaViaje
               };
            });
         default:
            return [];
      }
   };

   const getCosto = (viaje: any) => {
      if (viaje.pagos.length > 0 && viaje.pagos[0].idMetodoPago === 1) {
         return parseFloat(viaje.costoFinal).toFixed(2);
      }
      return parseFloat(viaje.costo).toFixed(2);
   };

   const getTableColumns = (): any[] => {
      switch (tabValue) {
         case 0: // En curso
            return [
               {
                  dataField: 'idViaje',
                  text: 'ID',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'estatus',
                  text: 'Estatus',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark',
                  formatter: estatusBadgeFormatter
               },
               {
                  dataField: 'nombreChofer',
                  text: 'Chofer',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'nombreCliente',
                  text: 'Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'telefonoCliente',
                  text: 'Teléfono Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'horaInicio',
                  text: 'Hora de inicio',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'opciones',
                  text: 'Opciones',
                  isDummyField: true,
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark',
                  formatter: actionsFormatter
               }
            ];
         case 1: // Terminados
            return [
               {
                  dataField: 'idViaje',
                  text: 'ID',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'nombreChofer',
                  text: 'Chofer',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'nombreCliente',
                  text: 'Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'telefonoCliente',
                  text: 'Teléfono Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'horaTerminacion',
                  text: 'Hora de terminación',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'monto',
                  text: 'Monto',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'opciones',
                  text: 'Opciones',
                  isDummyField: true,
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark',
                  formatter: actionsFormatter
               }
            ];
         case 2: // Cancelados
            return [
               {
                  dataField: 'idViaje',
                  text: 'ID',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'quienCancelo',
                  text: '¿Quién canceló?',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               // {
               //    dataField: 'dondeCancelo',
               //    text: '¿Dónde canceló?',
               //    headerAlign: 'center',
               //    align: 'center',
               //    headerClasses: 'font-weight-bold text-dark',
               //    formatter: estatusBadgeFormatter
               // },
               {
                  dataField: 'nombreChofer',
                  text: 'Chofer',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'nombreCliente',
                  text: 'Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'telefonoCliente',
                  text: 'Teléfono Cliente',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'horaCancelacion',
                  text: 'Hora de cancelación',
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark'
               },
               {
                  dataField: 'opciones',
                  text: 'Opciones',
                  isDummyField: true,
                  headerAlign: 'center',
                  align: 'center',
                  headerClasses: 'font-weight-bold text-dark',
                  formatter: actionsFormatter
               }
            ];
         default:
            return [];
      }
   };

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabValue(newValue);
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      switch (tabValue) {
         case 0: // En curso
            fetchViajes();
            break;
         case 1: // Terminados/Finalizados
            fetchViajes(6);
            break;
         case 2: // Cancelados
            fetchViajes(3);
            break;
      }
   };

   // Refresca la lista cada 30seg
   useInterval(() => {
      handleRefresh();
   }, 30000);

   const handleCleanFilters = () => {
      setSearch('');
      if (tabValue === 0) {
         // Viajes en curso
         setFilters({
            estatus: null
         });
      }
   };

   const handleTableChange = useCallback((type, { page, sizePerPage }) => {
      setPage(page);
   }, []);

   const handleChangeSelect = inputName => (option, { action }) => {
      setFilters({
         ...filters,
         [inputName]: option.value.toString()
      });
   };

   const handleCancelViaje = async (id: number) => {
      try {
         const result = await Swal.fire({
            title: `¿Estás seguro que deseas cancelar el viaje?`,
            text: 'No podras revertir esta acción',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            customClass: {
               confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
               cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
            },
            buttonsStyling: false
         });
         if (result.value) {
            await http.put(`viajes/${id}/cancelar`);
            handleRefresh();
         }
      } catch (error) {
         apiErrorHandler('Viaje', error);
      }
   };

   return (
      <Fragment>
         <AppBar className='bg-primary' position='static'>
            <Tabs value={tabValue} onChange={handleTabChange}>
               <Tab label='Viajes en curso' />
               <Tab label='Viajes terminados' />
               <Tab label='Viajes cancelados' />
            </Tabs>
         </AppBar>
         <MDBCardBody className='px-3 pb-3 pt-0'>
            <div className='table-filters py-2'>
               <MDBRow className=''>
                  <MDBCol md='9'>
                     <MDBInput
                        className='m-0'
                        label='Buscar por chofer, cliente, ID'
                        outline
                        icon='search'
                        iconSize='lg'
                        onChange={handleSearchChange}
                        value={search}
                     />
                  </MDBCol>
                  <MDBCol md='3'>
                     <div style={{ marginTop: '0.6rem' }}>
                        <Tooltip title='Actualizar' placement='top'>
                           <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                              <MDBIcon size='2x' icon='sync' fixed />
                           </MDBBtn>
                        </Tooltip>
                        <Tooltip title='Limpiar Filtros' placement='top'>
                           <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                              <MDBIcon size='2x' icon='eraser' fixed />
                           </MDBBtn>
                        </Tooltip>
                     </div>
                  </MDBCol>
               </MDBRow>

               {/* FILTROS */}
               {tabValue === 0 && (
                  <MDBRow className='mb-3 mt-0 mx-0'>
                     <MDBCol className='pl-0' md='3'>
                        <SelectInput
                           name='estatus'
                           placeholder='Estatus'
                           options={estatusOptions}
                           handleCustomSelect={handleChangeSelect}
                           value={filters.estatus}
                        />
                     </MDBCol>
                  </MDBRow>
               )}
            </div>
            <MonitorViajesTabla
               columns={columns}
               viajes={viajes}
               isTableLoading={isTableLoading}
               page={page}
               pagination={pagination}
               handleTableChange={handleTableChange}
            />
         </MDBCardBody>
      </Fragment>
   );
};

export default MonitorViajesLista;
