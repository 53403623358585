import React, { useState, useEffect, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBNav, MDBNavItem } from 'mdbreact';
import { Bar, Pie } from 'react-chartjs-2';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import useScrollTop from 'hooks/useScrollTop';
import http from 'services/http.service';

export interface ChoferStatsProps extends RouteComponentProps {}

const ChoferStats: React.FC<ChoferStatsProps> = ({ match }) => {
   const choferId = match.params['id'];
   const [choferStats, setChoferStats] = useState<any>();
   const [tabValue, setTabValue] = useState<number>(0);
   const [pillValue, setPillValue] = useState<number>(1);
   const [barChart, setBarChart] = useState<any>({
      labels: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
      datasets: [
         {
            label: 'Horas',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4'],
            borderColor: ['#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4']
         }
      ]
   });
   const barChartOptions = {
      maintainAspectRatio: false,
      scales: {
         xAxes: [
            {
               barPercentage: 1,
               gridLines: {
                  display: false
               }
            }
         ],
         yAxes: [
            {
               gridLines: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)'
               },
               ticks: {
                  beginAtZero: true
               }
            }
         ]
      },
      legend: {
         display: false
      }
   };
   const [pieChart, setPieChart] = useState({
      // labels: ['Aceptadas', 'Rechazadas', 'Sin taxista disponible', 'Taxistas que no aceptaron'],
      datasets: [
         {
            data: [300, 50],
            backgroundColor: ['#003F5C', '#FFA600'],
            hoverBackgroundColor: ['#003F5C', '#FFA600']
         }
      ]
   });

   // SmoothScroll
   useScrollTop();

   useEffect(() => {
      const fetchChoferStats = async () => {
         try {
            const chofer: any = await http.get(`choferes/${choferId}`);
            setChoferStats(chofer);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ha ocurrido un error al obtener los datos, intente de nuevo.</ToastMessage>);
         }
      };
      fetchChoferStats();
   }, [choferId]);

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabValue(newValue);
   };

   const handleClickPill = (value: number) => e => {
      setPillValue(value);
   };

   return (
      <section id='choferes'>
         <MDBRow className='mb-4'>
            <MDBCol className='align-self-center'>
               <header>
                  <h3 className='mb-0'>
                     <Link className='text-dark' to='/r/choferes'>
                        <MDBIcon className='mr-3' icon='arrow-left' />
                     </Link>
                     Estadísticas de chofer
                  </h3>
               </header>
            </MDBCol>
         </MDBRow>

         <MDBCard>
            <AppBar className='bg-primary' position='static'>
               <Tabs
                  classes={{
                     indicator: 'Tab-stat-indicator'
                  }}
                  value={tabValue}
                  onChange={handleTabChange}>
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Horas conexión'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Solicitudes'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Viajes'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Cancelaciones'
                  />
               </Tabs>
            </AppBar>
            <MDBCardBody className='px-4 pt-3 pb-4'>
               {tabValue === 0 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Hoy
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Mes
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICA */}
                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>
                  </Fragment>
               )}
               {tabValue === 1 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Hoy
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Mes
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICA */}
                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>
                  </Fragment>
               )}
               {tabValue === 2 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Hoy
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Mes
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICAS */}
                     <MDBRow>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                     </MDBRow>
                  </Fragment>
               )}
               {tabValue === 3 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Hoy
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Mes
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICAS */}
                     <MDBRow>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                        <MDBCol className='text-center' md='4'>
                           <p className='mb-0'>Lunes</p>
                           <p>04 de Marzo</p>
                           <div className='pie-chart w-100 mb-5' style={{ height: '25rem' }}>
                              <Pie data={pieChart} options={{ maintainAspectRatio: false }} />
                           </div>
                        </MDBCol>
                     </MDBRow>
                  </Fragment>
               )}
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ChoferStats;
