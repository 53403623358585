import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBBadge } from 'mdbreact';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import useScrollTop from 'hooks/useScrollTop';
import http from 'services/http.service';

export interface UsuarioDetallesProps extends RouteComponentProps {}

const UsuarioDetalles: React.FC<UsuarioDetallesProps> = ({ match }) => {
   const usuarioId = match.params['id'];
   const [usuario, setUsuario] = useState<any>();

   // SmoothScroll
   useScrollTop();

   // Obtener los datos del usuario
   useEffect(() => {
      const fetchUsuario = async () => {
         try {
            const usuarioData: any = await http.get(`usuarios/${usuarioId}`);
            setUsuario(usuarioData);
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>
                     Ha ocurrido un error al obtener los datos del usuario, intente de nuevo
                  </ToastMessage>
               );
            }
         }
      };

      fetchUsuario();
   }, [usuarioId]);

   const handleToggleEstado = async () => {
      const result = await Swal.fire({
         title: `¿Estás seguro que deseas ${usuario.activo ? 'desactivar' : 'activar'} este usuario?`,
         text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         try {
            await http.put(`usuarios/${usuarioId}/${usuario.activo ? 'desactivar' : 'activar'}`);
            setUsuario({
               ...usuario,
               activo: !usuario.activo
            });
            toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al cambiar el estado, intente de nuevoe.</ToastMessage>
            );
         }
      }
   };

   return (
      <section id='usuario'>
         <div className='container'>
            <MDBRow className='mb-4'>
               <MDBCol className='align-self-center'>
                  <header>
                     <h3 className='mb-0'>
                        <Link className='text-dark' to='/r/usuarios'>
                           <MDBIcon className='mr-3' icon='arrow-left' />
                        </Link>
                        Detalles del usuario
                     </h3>
                  </header>
               </MDBCol>
               <MDBCol className='text-right'>
                  {usuario && usuario.activo && (
                     <Link to={`/r/usuarios/${usuarioId}/editar`}>
                        <MDBBtn color='indigo' className='text-capitalize btn-top-action'>
                           <MDBIcon className='mr-2' icon='edit' size='lg' />
                           Editar
                        </MDBBtn>
                     </Link>
                  )}
                  <MDBBtn
                     color={usuario && usuario.activo ? 'success' : 'danger'}
                     className='text-capitalize btn-top-action'
                     onClick={handleToggleEstado}>
                     <MDBIcon className='mr-2' icon='power-off' size='lg' />
                     {usuario && usuario.activo ? 'Activo' : 'Desactivado'}
                  </MDBBtn>
               </MDBCol>
            </MDBRow>
            <MDBRow>
               <MDBCol>
                  {/* INFORMACION DE USUARIO */}
                  <MDBCard className='mb-4'>
                     <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold'>
                        <MDBIcon size='lg' far icon='id-card' className='mr-3' />
                        Información del usuario
                     </div>
                     <MDBCardBody className='p-4'>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Nombre del usuario
                              </label>
                              <p>{usuario ? `${usuario.nombres} ${usuario.primerApellido} ${usuario.segundoApellido}` : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Tipo de usuario
                              </label>
                              <p>{usuario ? usuario.tipoUsuario.nombre : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Estado
                              </label>
                              <p>
                                 {usuario ? (
                                    usuario.activo ? (
                                       <MDBBadge color='success px-4'>Activo</MDBBadge>
                                    ) : (
                                       <MDBBadge color='danger'>Desactivado</MDBBadge>
                                    )
                                 ) : (
                                    'N/A'
                                 )}
                              </p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Correo electrónico
                              </label>
                              <p>{usuario ? usuario.email : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Télefono
                              </label>
                              <p>{usuario ? usuario.telefono : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCard>
               </MDBCol>
            </MDBRow>
         </div>
      </section>
   );
};

export default UsuarioDetalles;
