import React, { useState, useEffect, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { MDBInput, MDBBtn, MDBAlert, MDBIcon } from 'mdbreact';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { Alert } from 'typings/General';

const ChangePassword: React.FC<RouteComponentProps> = ({ match, history }) => {
   const [errorAlert, setErrorAlert] = useState<Alert>({
      isOpen: false,
      message: ''
   });
   const [successAlert, setSuccessAlert] = useState<Alert>({
      isOpen: false,
      message: ''
   });
   const initialValues = {
      newPassword: '',
      confirmPassword: ''
   };
   const token = match.params['token'];

   const newPasswordSchema = Yup.object().shape({
      newPassword: Yup.string()
         .min(6, 'Mínimo 6 caracteres')
         .max(50, 'Maximo 50 caracteres')
         .required('Campo requerido'),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
         .required('Campo requerido')
   });

   useEffect(() => {
      const checkToken = async () => {
         try {
            await http.get(`auth/forgot?token=${token}`);
         } catch (error) {
            if (error.type === 'Auth/TokenNotFound') {
               setErrorAlert({
                  isOpen: true,
                  message: 'El link para recuperar contraseña ha caducado.'
               });
            }
            if (error.type === 'Auth/AccountNotFound') {
               setErrorAlert({
                  isOpen: true,
                  message: 'Esta cuenta ya no existe.'
               });
            }
         }
      };

      if (token) {
         checkToken();
      }
   }, [token]);

   const handleFormSubmit = async (values, actions) => {
      try {
         await http.post('auth/newpassword', {
            password: values.newPassword,
            token
         });
         setSuccessAlert({
            isOpen: true,
            message: 'Tu contraseña se ha cambiado correctamente, serás redireccionado...'
         });
         setTimeout(() => {
            history.replace('/login');
         }, 3000);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/TokenNotFound') {
            setErrorAlert({
               isOpen: true,
               message: 'El link para recuperar contraseña ha caducado.'
            });
         }
      }
   };

   const handleCloseAlert = () => {
      if (errorAlert.isOpen) {
         setErrorAlert({
            isOpen: false,
            message: ''
         });
      } else if (successAlert.isOpen) {
         setSuccessAlert({
            isOpen: false,
            message: ''
         });
      }
   };

   return (
      <Fragment>
         <Link to='' className='text-dark header-link'>
            Crear nueva contraseña
         </Link>
         <p className='mt-4 mb-4'>Introduce tu nueva contraseña</p>
         {errorAlert.isOpen ? (
            <MDBAlert color='danger' dismiss onClose={handleCloseAlert}>
               {errorAlert.message}
            </MDBAlert>
         ) : null}
         {successAlert.isOpen ? (
            <MDBAlert color='success' dismiss onClose={handleCloseAlert}>
               {successAlert.message}
            </MDBAlert>
         ) : null}
         <Formik
            initialValues={initialValues}
            validationSchema={newPasswordSchema}
            onSubmit={handleFormSubmit}
            render={({ isSubmitting }: FormikProps<any>) => (
               <Form className='login-form'>
                  <Field
                     name='newPassword'
                     render={({ field }) => <MDBInput icon='key' outline label='Contraseña' type='password' {...field} />}
                  />
                  <ErrorMessage name='newPassword' component={ErrorFeedback} />

                  <Field
                     name='confirmPassword'
                     render={({ field }) => (
                        <MDBInput icon='key' outline label='Confirmar contraseña' type='password' {...field} />
                     )}
                  />
                  <ErrorMessage
                     name='confirmPassword'
                     render={msg => <div className='invalid-field text-left ml-2'>{msg}</div>}
                  />

                  <MDBBtn color='default' type='submit' className='btn-login mt-4' block disabled={isSubmitting}>
                     {isSubmitting ? <MDBIcon icon='spinner' pulse size='lg' /> : 'Cambiar contraseña'}
                  </MDBBtn>
               </Form>
            )}
         />
      </Fragment>
   );
};

export default ChangePassword;
