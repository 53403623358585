import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBAlert, MDBIcon } from 'mdbreact';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
// Mis Componentes
import SelectInput from 'components/shared/SelectInput';
import FileInput from 'components/shared/FileInput';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { getCurrentUser } from 'services/authentication.service';
// Mis Types
import { Chofer } from 'models/Choferes';
import { Option } from 'typings/General';

interface InformacionProps {
   chofer: Chofer;
   isChoferEdit: boolean;
   tiposChofer: Option[];
   zonas: Option[];
   onStepSubmit: (values, actions) => void;
}

const Informacion: React.FC<InformacionProps> = ({ chofer, isChoferEdit, tiposChofer, zonas, onStepSubmit }) => {
   const user = getCurrentUser();
   const FILE_SIZE = 0.5 * 1024 * 1024; //0.5MB
   const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
   const initialValues = {
      idTipoChofer: chofer.idTipoChofer,
      idZona: chofer.idZona,
      nombres: chofer.nombres,
      primerApellido: chofer.primerApellido,
      segundoApellido: chofer.segundoApellido,
      email: chofer.email,
      telefono: chofer.telefono,
      password: chofer.password,
      confirmPassword: '',
      imgPerfil: chofer.imgPerfil
   };

   const informacionSchema = Yup.object().shape({
      idTipoChofer: Yup.number()
         .nullable()
         .required('Campo requerido'),
      idZona: Yup.number()
         .nullable()
         .required('Campo requerido'),
      nombres: Yup.string().required('Campo requerido'),
      primerApellido: Yup.string().required('Campo requerido'),
      segundoApellido: Yup.string().required('Campo requerido'),
      email: Yup.string()
         .email('Escriba un email válido')
         .required('Campo requerido'),
      telefono: Yup.string()
         .matches(/^\d{10}$/, 'Ingrese un teléfono válido')
         .required('Campo requerido'),
      ...(isChoferEdit
         ? {
              password: Yup.string()
                 .min(6, 'Mínimo 6 caracteres')
                 .max(50, 'Maximo 50 caracteres'),
              confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
           }
         : {
              password: Yup.string()
                 .min(6, 'Mínimo 6 caracteres')
                 .max(50, 'Maximo 50 caracteres')
                 .required('Campo requerido'),
              confirmPassword: Yup.string()
                 .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
                 .required('Campo requerido')
           }),
      ...(chofer.imgPerfil
         ? {
              imgPerfil: Yup.mixed()
                 .notRequired()
                 .test('fileFormat', 'Formato no permitido. Solo jpg, jpeg, png', value => {
                    return !value || typeof value === 'string' ? true : value && SUPPORTED_FORMATS.includes(value.type);
                 })
                 // .test('fileDimensions', 'Archivo no corresponde con las medidas 330px x 100px', value =>
                 //    !value || typeof value === 'string' ? true : value && (value.width === 330 && value.height === 100)
                 // )
                 .test('fileSize', 'Archivo demasiado grande. Máximo 512KB', value =>
                    !value || typeof value === 'string' ? true : value && value.size <= FILE_SIZE
                 )
           }
         : {
              imgPerfil: Yup.mixed()
                 .required('Foto requerida')
                 .test(
                    'fileFormat',
                    'Formato no permitido. Solo jpg, jpeg, png',
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                 )
                 .test('fileSize', 'Archivo demasiado grande. Máximo 512KB', value => value && value.size <= FILE_SIZE)
           })
   });
   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         validationSchema={informacionSchema}
         onSubmit={onStepSubmit}
         render={({ setFieldValue }: FormikProps<any>) => (
            <Form>
               <p className='text-muted font-size-18 mb-1'>Tipo de chofer</p>

               <MDBRow className='mb-4'>
                  <MDBCol md='3'>
                     <Field
                        name='idTipoChofer'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Elegir tipo'
                              options={tiposChofer}
                              isSearchable={false}
                              setFieldValue={setFieldValue}
                           />
                        )}
                     />
                     <ErrorMessage name='idTipoChofer' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='3'>
                     <Field
                        name='idZona'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Elegir zona'
                              options={zonas}
                              setFieldValue={setFieldValue}
                              isDisabled={[3, 4, 6].includes(user.tipo) && isChoferEdit}
                           />
                        )}
                     />
                     <ErrorMessage name='idZona' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <hr />

               <p className='text-muted mt-4 mb-1 font-size-18'>Información básica</p>

               <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                     <Field name='nombres' render={({ field }) => <MDBInput {...field} outline label='Nombres' type='text' />} />
                     <ErrorMessage name='nombres' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='primerApellido'
                        render={({ field }) => <MDBInput {...field} outline label='Apellido Paterno' type='text' />}
                     />
                     <ErrorMessage name='primerApellido' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='segundoApellido'
                        render={({ field }) => <MDBInput {...field} outline label='Apellido Materno' type='text' />}
                     />
                     <ErrorMessage name='segundoApellido' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <MDBRow className='mb-4'>
                  <MDBCol md='4'>
                     <Field
                        name='telefono'
                        render={({ field }) => <MDBInput {...field} outline label='Teléfono' type='text' />}
                     />
                     <ErrorMessage name='telefono' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <hr />

               <p className='text-muted mt-4 mb-1 font-size-18'>Información de inicio de sesión del chofer</p>

               <MDBRow>
                  <MDBCol md='4'>
                     <Field
                        name='email'
                        render={({ field }) => (
                           <MDBInput {...field} outline label='Correo Electrónico' type='text' autoComplete='off' />
                        )}
                     />
                     <ErrorMessage name='email' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='password'
                        render={({ field }) => (
                           <MDBInput {...field} outline label='Contraseña' type='password' autoComplete='off' />
                        )}
                     />
                     <ErrorMessage name='password' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='confirmPassword'
                        render={({ field }) => (
                           <MDBInput {...field} outline label='Repetir contraseña' type='password' autoComplete='off' />
                        )}
                     />
                     <ErrorMessage name='confirmPassword' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               {isChoferEdit && (
                  <MDBRow className='justify-content-end mt-3'>
                     <MDBCol md='8'>
                        <MDBAlert className='mb-0' color='warning'>
                           <MDBIcon icon='exclamation-triangle mr-2' size='lg' />
                           Deje el campo Contraseña en blanco sino desea cambiar la contraseña actual.
                        </MDBAlert>
                     </MDBCol>
                  </MDBRow>
               )}

               <Field name='imgPerfil' component={FileInput} />
               <ErrorMessage name='imgPerfil' component={ErrorFeedback} />

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default Informacion;
