import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from 'mdbreact';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import SelectInput from 'components/shared/SelectInput';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
// Mis Types
import { Filters, Pagination } from 'typings/Tablas';
import { Options } from 'typings/General';
import { mapOptionsToViewModel } from 'utils';
import ClientesTabla from './ClientesTabla';

export interface ClientesListaProps { }

const ClientesLista: React.FC<ClientesListaProps> = () => {
   const [clientes, setClientes] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [options, setOptions] = useState<Options>({
      estatus: [
         {
            value: 'false',
            label: 'Bloqueado'
         },
         {
            value: 'true',
            label: 'Activo'
         }
      ],
      tipoCliente: []
   });
   const [filters, setFilters] = useState<Filters>({
      estatus: null,
      tipoCliente: null
   });
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   useEffect(() => {
      const fetchFiltros = async () => {
         try {
            const { rows: tiposCliente }: any = await http.get('catalogos/TiposCliente');
            setOptions({
               ...options,
               tipoCliente: mapOptionsToViewModel(tiposCliente)
            });
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
         }
      };

      fetchFiltros();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      fetchClientes();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page, filters.estatus, filters.tipoCliente]);

   const fetchClientes = async () => {
      const { estatus, tipoCliente } = filters;
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            ...(estatus !== null ? { activo: estatus } : {}),
            ...(tipoCliente ? { idTipoCliente: tipoCliente } : {}),
            order: 'desc',
            limit,
            page
         };
         const { rows: clientesList, count: totalSize }: any = await http.get('clientes', { params });
         setClientes(mapClientesToViewModel(clientesList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de clientes.</ToastMessage>);
      }
   };

   const mapClientesToViewModel = (clientes: any[]) => {
      return clientes.map(cliente => {
         return {
            idCliente: cliente.idCliente,
            nombre: cliente.nombre,
            telefono: cliente.telefono,
            estado: cliente.activo
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchClientes();
   };

   const handleCleanFilters = () => {
      setSearch('');
      setFilters({
         estatus: null,
         tipoCliente: null
      });
   };

   const handleChangeSelect = inputName => (option, { action }) => {
      setFilters({
         ...filters,
         [inputName]: option.value
      });
   };

   const handleToggleEstado = useCallback(
      async (id: number, activo: boolean, index: number) => {
         try {
            const result = await Swal.fire({
               title: `¿Estás seguro que deseas ${activo ? 'bloquear' : 'desbloquear'} al cliente?`,
               text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
               type: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Aceptar',
               cancelButtonText: 'Cancelar',
               customClass: {
                  confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
                  cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
               },
               buttonsStyling: false
            });
            if (result.value) {
               await http.put(`clientes/${id}/${activo ? 'desactivar' : 'activar'}`);

               let clientesAux = [...clientes];
               // Actualizar UI
               clientesAux[index].estado = !clientesAux[index].estado;
               setClientes(clientesAux);
            }
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>
               );
            }
         }
      },
      [clientes]
   );

   const handleTableChange = useCallback(
      (type, { page, sizePerPage }) => {
         setPagination({
            ...pagination,
            page
         });
      },
      [pagination]
   );

   return (
      <Fragment>
         <div className='table-filters py-2'>
            <MDBRow className=''>
               <MDBCol md='9'>
                  <MDBInput
                     className='m-0'
                     label='Buscar por nombre, correo, teléfono...'
                     outline
                     icon='search'
                     iconSize='lg'
                     onChange={handleSearchChange}
                     value={search}
                  />
               </MDBCol>
               <MDBCol md='3'>
                  <div style={{ marginTop: '0.6rem' }}>
                     <Tooltip title='Actualizar' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                           <MDBIcon size='2x' icon='sync' fixed />
                        </MDBBtn>
                     </Tooltip>
                     <Tooltip title='Limpiar Filtros' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                           <MDBIcon size='2x' icon='eraser' fixed />
                        </MDBBtn>
                     </Tooltip>
                  </div>
               </MDBCol>
            </MDBRow>

            {/* FILTROS */}
            <MDBRow className='mb-3 mt-0 mx-0'>
               <MDBCol className='pl-0' md='3'>
                  <SelectInput
                     name='estatus'
                     placeholder='Estatus'
                     options={options.estatus}
                     isSearchable={false}
                     handleCustomSelect={handleChangeSelect}
                     value={filters.estatus}
                  />
               </MDBCol>
               <MDBCol className='pl-0' md='3'>
                  <SelectInput
                     name='tipoCliente'
                     placeholder='Tipo'
                     options={options.tipoCliente}
                     isSearchable={false}
                     handleCustomSelect={handleChangeSelect}
                     value={filters.tipoCliente}
                  />
               </MDBCol>
            </MDBRow>
         </div>

         <ClientesTabla
            clientes={clientes}
            isTableLoading={isTableLoading}
            pagination={pagination}
            handleTableChange={handleTableChange}
            handleToggleEstado={handleToggleEstado}
         />
         {/* <BootstrapTable
            bootstrap4
            condensed
            classes='clientes-table'
            keyField='idCliente'
            data={clientes}
            columns={columns}
            remote={{
               pagination: true
            }}
            loading={isTableLoading}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            hover
            bordered={false}
            noDataIndication='No se encontraron registros.'
            rowClasses={'font-weight-bold'}
            pagination={paginationFactory({
               page: pagination.page, // Specify the current page.
               totalSize: pagination.totalSize, // Total data size.
               sizePerPage: pagination.sizePerPage, // Specify the size per page.
               withFirstAndLast: false, // hide the going to first and last page button
               alwaysShowAllBtns: true, // always show the next and previous page button
               prePageText: 'Anterior',
               nextPageText: 'Siguiente',
               hideSizePerPage: true, // hide the size per page dropdown
               hidePageListOnlyOnePage: true
            })}
            onTableChange={handleTableChange}
         /> */}
      </Fragment>
   );
};

export default ClientesLista;
