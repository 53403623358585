import { Option } from 'typings/General';

export const mapOptionsToViewModel = (options: any[]): Option[] => {
   return options.map(option => {
      return {
         value: option[Object.keys(option)[0]],
         label: option.nombre || option.banco || option.nombreZona
      };
   });
};

export const generateYears = () => {
   let years: Option[] = [];
   const currentYear = new Date().getFullYear() + 1;
   for (let startYear = 2005; startYear <= currentYear + 1; startYear++) {
      years.push({ value: startYear, label: startYear.toString() });
   }
   return years;
};

export const getDiaCorte = (id: number) => {
   switch (id) {
      case 0:
         return 'Lunes';
      case 1:
         return 'Martes';
      case 2:
         return 'Miercoles';
      case 3:
         return 'Jueves';
      case 4:
         return 'Viernes';
      case 5:
         return 'Sabado';
      case 6:
         return 'Domingo';
   }
};
