import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
// Mis Componentes
import SelectInput from 'components/shared/SelectInput';
import ErrorFeedback from 'components/shared/ErrorFeedback';
// Mis Types
import { Option } from 'typings/General';
import { Pagination } from 'typings/Tablas';
import { ChoferOptions } from 'models/Grupos';

interface AgregarChoferProps {
   idChoferLider: number | null;
   choferesLideres: Option[];
   miembros: any[];
   choferes: ChoferOptions[];
   onAddMiembro: () => void;
   onRemoveMiembro: (id: number) => void;
   // isTableLoading: boolean;
   pagination: Pagination;
   onTableChange: (type: any, { page, sizePerPage }: Pagination) => void;
   fetchSearchChoferes: (query: string) => Promise<void>;
   onSelectOption: ((selected: ChoferOptions[]) => void) | undefined;
   onBackBtnClick: () => void;
   onStepSubmit: (values, actions) => void;
}

const AgregarChofer: React.FC<AgregarChoferProps> = ({
   idChoferLider,
   choferesLideres,
   miembros,
   choferes,
   onAddMiembro,
   onRemoveMiembro,
   // isTableLoading,
   pagination,
   onTableChange,
   fetchSearchChoferes,
   onSelectOption,
   onBackBtnClick,
   onStepSubmit
}) => {
   const initialValues = { idChoferLider };
   const optionsFormatter = (cell, row) => {
      return (
         <MDBIcon className='text-danger cursor-pointer' size='2x' icon='times' onClick={() => onRemoveMiembro(row.idChofer)} />
      );
   };
   const columns = [
      {
         dataField: 'idChofer',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombre',
         text: 'Nombre',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'telefono',
         text: 'Teléfono',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'email',
         text: 'Correo electrónico',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'options',
         text: 'Opciones',
         isDummyField: true,
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: optionsFormatter
      }
   ];

   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         onSubmit={onStepSubmit}
         render={({ setFieldValue }: FormikProps<any>) => (
            <Form>
               <p className='text-muted mb-1 font-size-18'>
                  Seleccionar Lider de grupo
                  <small className='text-danger ml-2'>*(Primero agregue miembros al grupo para poder seleccionar el lider)</small>
               </p>
               <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                     <Field
                        name='idChoferLider'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Lider de grupo'
                              options={choferesLideres}
                              isSearchable={true}
                              setFieldValue={setFieldValue}
                           />
                        )}
                     />
                     <ErrorMessage name='idChoferLider' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <p className='text-muted mb-1 font-size-18'>
                  Buscar choferes para agregar al grupo
                  <small className='text-danger ml-2'>
                     *(Solo apareceran choferes activos, sin grupo asignado y de la zona seleccionada en el paso anterior)
                  </small>
               </p>
               <MDBRow className='mb-3'>
                  <MDBCol md='8'>
                     <AsyncTypeahead
                        id={1}
                        placeholder='Buscar por nombre, correo, teléfono, ID'
                        promptText='Escriba para buscar'
                        emptyLabel='No se encontraron resultados'
                        maxResults={10}
                        minLength={3}
                        delay={500}
                        isLoading={false}
                        onSearch={fetchSearchChoferes}
                        onChange={onSelectOption}
                        labelKey={(option: ChoferOptions) =>
                           `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`
                        }
                        clearButton={true}
                        options={choferes}
                     />
                  </MDBCol>
                  <MDBCol md='4'>
                     <div style={{ marginTop: '0.6rem' }}>
                        <MDBBtn color='primary' className='text-capitalize btn-top-action' onClick={onAddMiembro}>
                           <MDBIcon className='mr-2' icon='plus' size='lg' />
                           Agregar
                        </MDBBtn>
                     </div>
                  </MDBCol>
               </MDBRow>

               <hr />
               <p className='text-muted mt-4 mb-3 font-size-18'>Choferes en grupo</p>

               <BootstrapTable
                  bootstrap4
                  condensed
                  classes='grupo-table'
                  keyField='idChofer'
                  data={miembros}
                  columns={columns}
                  remote={{
                     pagination: true
                  }}
                  // loading={isTableLoading}
                  overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
                  hover
                  bordered={false}
                  noDataIndication='No hay choferes en el grupo.'
                  rowClasses={'font-weight-bold'}
                  pagination={paginationFactory({
                     page: pagination.page, // Specify the current page.
                     totalSize: pagination.totalSize, // Total data size.
                     sizePerPage: pagination.sizePerPage, // Specify the size per page.
                     withFirstAndLast: false, // hide the going to first and last page button
                     alwaysShowAllBtns: true, // always show the next and previous page button
                     prePageText: 'Anterior',
                     nextPageText: 'Siguiente',
                     hideSizePerPage: true, // hide the size per page dropdown
                     hidePageListOnlyOnePage: true
                  })}
                  onTableChange={onTableChange}
               />

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                        Atrás
                     </MDBBtn>
                  </MDBCol>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default AgregarChofer;
