import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import { Formik, Field, Form, ErrorMessage, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
// Mis Types
import { Config } from 'models/Config';

export interface FormConfiguracionProps {}

const FormConfiguracion: React.FC<FormConfiguracionProps> = () => {
   // FORM STATE
   const [config, setConfig] = useState<Config>({
      kmCarretera: undefined,
      minCarretera: undefined,
      kmOdometro: undefined,
      minOdometro: undefined,
      radioMax: undefined,
      tiempoEspera: undefined,
      distPuntoFinalizar: undefined,
      distPuntoLlegar: undefined,
      distCancelarViaje: undefined
   });

   const configSchema = Yup.object().shape({
      kmCarretera: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      minCarretera: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      kmOdometro: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      minOdometro: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      radioMax: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      tiempoEspera: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      distPuntoFinalizar: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      distPuntoLlegar: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.'),
      distCancelarViaje: Yup.number()
         .typeError('Solo números')
         .required('Campo requerido')
         .moreThan(0, 'Debe ser mayor a 0.')
   });

   // Obtener datos para editar usuario
   useEffect(() => {
      const fetchConfiguracion = async () => {
         try {
            const configData: any = await http.get(`config`);
            populateConfig(configData);
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener la configuración, intente de nuevo.</ToastMessage>
            );
         }
      };

      const populateConfig = config => {
         setConfig({
            kmCarretera: parseFloat(config.kmCarretera),
            minCarretera: parseFloat(config.minCarretera),
            kmOdometro: parseFloat(config.kmOdometro),
            minOdometro: parseFloat(config.minOdometro),
            radioMax: parseFloat(config.radioMax),
            tiempoEspera: config.tiempoEspera,
            distPuntoFinalizar: parseFloat(config.distPuntoFinalizar),
            distPuntoLlegar: parseFloat(config.distPuntoLlegar),
            distCancelarViaje: parseFloat(config.distCancelarViaje)
         });
      };

      fetchConfiguracion();
   }, []);

   const handleFormSubmit = async (values: Config, actions: FormikActions<Config>) => {
      try {
         const body = setBody(values);
         await http.put(`config`, body);

         await Swal.fire({
            title: 'Configuración actualizada',
            text: 'Los cambios han sido actualizados correctamente.',
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-info waves-effect waves-light text-capitalize'
            },
            buttonsStyling: false
         });
         actions.setSubmitting(false);
      } catch (error) {
         actions.setSubmitting(false);
         apiErrorHandler('Config', error);
      }
   };

   const setBody = (configUpdate: Config) => {
      const body: object = {};
      for (const [key, value] of Object.entries(config)) {
         if (value !== configUpdate[key]) {
            body[key] = configUpdate[key];
         }
      }
      return body;
   };

   return (
      <section id='config'>
         <div className='container'>
            <MDBCard className='mx-auto' style={{ maxWidth: '40rem' }}>
               <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold'>
                  <MDBIcon size='lg' icon='cog' className='mr-3' />
                  Modificar Variables Wiicab
               </div>
               <MDBCardBody className='p-4'>
                  <Formik
                     initialValues={config}
                     enableReinitialize={true}
                     validationSchema={configSchema}
                     onSubmit={handleFormSubmit}
                     render={({ isSubmitting }: FormikProps<Config>) => (
                        <Form className='config-form'>
                           <Field
                              name='kmCarretera'
                              render={({ field }) => (
                                 <MDBInput outline label='Costo x Km. fuera de zona' type='text' {...field} />
                              )}
                           />
                           <ErrorMessage name='kmCarretera' component={ErrorFeedback} />

                           <Field
                              name='minCarretera'
                              render={({ field }) => (
                                 <MDBInput outline label='Costo x Minuto fuera de zona' type='text' {...field} />
                              )}
                           />
                           <ErrorMessage name='minCarretera' component={ErrorFeedback} />

                           <Field
                              name='kmOdometro'
                              render={({ field }) => <MDBInput outline label='Costo x Km. Odómetro' type='text' {...field} />}
                           />
                           <ErrorMessage name='kmOdometro' component={ErrorFeedback} />

                           <Field
                              name='minOdometro'
                              render={({ field }) => <MDBInput outline label='Costo x Minuto Odómetro' type='text' {...field} />}
                           />
                           <ErrorMessage name='minOdometro' component={ErrorFeedback} />

                           <Field
                              name='radioMax'
                              render={({ field }) => <MDBInput outline label='Radio Máximo(Kilómetros)' type='text' {...field} />}
                           />
                           <ErrorMessage name='radioMax' component={ErrorFeedback} />

                           <Field
                              name='tiempoEspera'
                              render={({ field }) => <MDBInput outline label='Tiempo de espera (en ms)' type='text' {...field} />}
                           />
                           <ErrorMessage name='tiempoEspera' component={ErrorFeedback} />

                           <Field
                              name='distPuntoFinalizar'
                              render={({ field }) => (
                                 <MDBInput outline label='Distancia punto finalizar (en metros)' type='text' {...field} />
                              )}
                           />
                           <ErrorMessage name='distPuntoFinalizar' component={ErrorFeedback} />

                           <Field
                              name='distPuntoLlegar'
                              render={({ field }) => (
                                 <MDBInput outline label='Distancia punto de llegada (en metros)' type='text' {...field} />
                              )}
                           />
                           <ErrorMessage name='distPuntoLlegar' component={ErrorFeedback} />

                           <Field
                              name='distCancelarViaje'
                              render={({ field }) => (
                                 <MDBInput outline label='Distancia cancelar peticion/viaje (en metros)' type='text' {...field} />
                              )}
                           />
                           <ErrorMessage name='distCancelarViaje' component={ErrorFeedback} />

                           <MDBBtn color='default' type='submit' className='btn-login mt-4' block disabled={isSubmitting}>
                              {isSubmitting ? <MDBIcon icon='spinner' pulse size='lg' /> : 'Actualizar'}
                           </MDBBtn>
                        </Form>
                     )}
                  />
               </MDBCardBody>
            </MDBCard>
         </div>
      </section>
   );
};

export default FormConfiguracion;
