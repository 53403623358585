import React from 'react';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/locale/es';
import DateTime from 'react-datetime';

export interface DatepickerProps {
   name: string;
   labelText: string;
   setFieldValue: any;
   value: string;
}

const Datepicker: React.FC<DatepickerProps> = ({ name, labelText, setFieldValue, value }) => {
   let date;
   const handleDateChange = (value: string | moment.Moment) => {
      if (value instanceof moment) {
         date = moment(value).toISOString();
         setFieldValue(name, date);
      } else {
         setFieldValue(name, 'invalid');
      }
   };

   return (
      <div className='md-form md-outline'>
         <i className='fas fa-calendar-alt prefix' />
         <DateTime
            locale='es'
            inputProps={{ name, className: 'form-control date-input', readOnly: true }}
            closeOnSelect={true}
            dateFormat='DD/MM/YYYY'
            timeFormat={false}
            onChange={handleDateChange}
            value={value ? new Date(value) : undefined}
         />
         <label id={name} className='active' htmlFor={name}>
            {labelText}
         </label>
      </div>
   );
};

export default Datepicker;
