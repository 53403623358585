import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { FieldProps } from 'formik';
import { Tarifa } from 'models/Zonas';

export interface SwitchInputProps extends FieldProps, Omit<SwitchProps, 'form' | 'name' | 'value' | 'defaultChecked'> {
   label: string;
   tarifas?: Tarifa[];
   unshift?: (value: any) => number;
   remove?: <T>(index: number) => T | undefined;
   onSwitchChange: (inputName, setFieldValue, tarifas, unshift, remove) => (event, checked) => Promise<void>;
}

export const fieldToSwitch = ({
   field,
   form: { isSubmitting, setFieldValue },
   tarifas,
   unshift,
   remove,
   disabled,
   onSwitchChange,
   ...props
}: SwitchInputProps): SwitchProps => {
   const fieldMap = {
      name: field.name,
      value: field.value
   };
   return {
      onChange: onSwitchChange(field.name, setFieldValue, tarifas, unshift, remove),
      disabled: disabled !== undefined ? disabled : isSubmitting,
      ...props,
      ...fieldMap,
      value: field.name,
      checked: field.value
   };
};

const SwitchInput: React.ComponentType<SwitchInputProps> = (props: SwitchInputProps) => {
   return (
      <p className='mb-2'>
         {props.label}
         <span className='font-weight-medium ml-4'>
            No
            <Switch color='primary' {...fieldToSwitch(props)} />
            Sí
         </span>
      </p>
   );
};

export default SwitchInput;
