import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBCollapse, MDBBadge, MDBMedia } from 'mdbreact';
import Rating from 'react-rating';
import Swal from 'sweetalert2';
// import moment from 'moment';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import useScrollTop from 'hooks/useScrollTop';
import http from 'services/http.service';
import ClienteQuejasLista from './ClienteQuejasLista';
import HistorialViajesLista from './HistorialViajesLista';

interface Cliente {
   idCliente: number;
   nombre: string | null;
   activo: boolean;
   email: string | null;
   telefono: string;
   calificacion: string;
   tipoCliente: {
      idTipoCliente: number;
      nombre: string;
      image_url: string;
   };
}

export interface ClienteDetallesProps extends RouteComponentProps {}

const ClienteDetalles: React.FC<ClienteDetallesProps> = ({ match }) => {
   const clienteId = match.params['id'];
   const [cliente, setCliente] = useState<Cliente>({
      idCliente: 0,
      nombre: '-',
      activo: false,
      email: '-',
      telefono: '-',
      calificacion: '5.000',
      tipoCliente: {
         idTipoCliente: 0,
         nombre: '-',
         image_url: '-'
      }
   });
   const [collapseId, setCollapseId] = useState<string>('');

   // SmoothScroll
   useScrollTop();

   // Obtener los datos del cliente
   useEffect(() => {
      const fetchCliente = async () => {
         try {
            const clienteData: any = await http.get(`clientes/${clienteId}`);
            setCliente(clienteData);
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>
                     Ha ocurrido un error al obtener los datos del cliente, intente de nuevo.
                  </ToastMessage>
               );
            }
         }
      };
      fetchCliente();
   }, [clienteId]);

   const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ''));

   const handleToggleEstado = async () => {
      const result = await Swal.fire({
         title: `¿Estás seguro que deseas ${cliente.activo ? 'desactivar' : 'activar'} al cliente?`,
         text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         try {
            await http.put(`clientes/${clienteId}/${cliente.activo ? 'desactivar' : 'activar'}`);
            setCliente({
               ...cliente,
               activo: !cliente.activo
            });
            toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
         }
      }
   };

   return (
      <section id='clientes'>
         <MDBRow className='mb-4'>
            <MDBCol className='align-self-center'>
               <header>
                  <h3 className='mb-0'>
                     <Link className='text-dark' to='/r/clientes'>
                        <MDBIcon className='mr-3' icon='arrow-left' />
                     </Link>
                     Detalles del cliente
                  </h3>
               </header>
            </MDBCol>
            <MDBCol className='text-right'>
               <MDBBtn
                  color={cliente && cliente.activo ? 'success' : 'danger'}
                  className='text-capitalize btn-top-action'
                  onClick={handleToggleEstado}>
                  <MDBIcon className='mr-2' icon='power-off' size='lg' />
                  {cliente && cliente.activo ? 'Activo' : 'Desactivado'}
               </MDBBtn>
            </MDBCol>
         </MDBRow>

         <MDBRow>
            <MDBCol md='8'>
               {/* INFORMACION DE CLIENTE */}
               <MDBCard className='mb-4'>
                  <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold'>
                     <MDBIcon size='lg' far icon='id-card' className='mr-3' />
                     Información del cliente
                  </div>
                  <MDBCardBody className='p-4'>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              ID del cliente
                           </label>
                           <p>{cliente.idCliente}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Nombre del cliente
                           </label>
                           <p>{cliente.nombre}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Estado
                           </label>
                           <p>
                              {cliente.activo ? (
                                 <MDBBadge color='success px-4'>Activo</MDBBadge>
                              ) : (
                                 <MDBBadge color='danger'>Desactivado</MDBBadge>
                              )}
                           </p>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Télefono
                           </label>
                           <p>{cliente.telefono}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Correo electrónico
                           </label>
                           <p>{cliente.email}</p>
                        </MDBCol>
                        {/* <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Contraseña
                           </label>
                           <p>***********</p>
                        </MDBCol> */}
                        {/* <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Método de registro
                           </label>
                           <p>{cliente ? cliente.telefono : 'N/A'}</p>
                        </MDBCol> */}
                        {/* <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Dispositivo
                           </label>
                           <p>{cliente ? cliente.telefono : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Sistema
                           </label>
                           <p>{cliente ? cliente.telefono : 'N/A'}</p>
                        </MDBCol> */}
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>

               {/* QUEJAS */}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('quejas')}>
                     <span>
                        <MDBIcon size='lg' icon='info-circle' className='mr-3' />
                        Quejas
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'quejas' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='quejas' isOpen={collapseId}>
                     <MDBCardBody className='px-4 pb-4 pt-1'>
                        <ClienteQuejasLista idCliente={clienteId} />
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>

               {/* HISTORIAL DE VIAJES */}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('historial-viajes')}>
                     <span>
                        <MDBIcon size='lg' icon='history' className='mr-3' />
                        Historial de viajes
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'historial-viajes' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='historial-viajes' isOpen={collapseId}>
                     <MDBCardBody className='px-4 pb-4 pt-1'>
                        <HistorialViajesLista idCliente={clienteId} />
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>
            </MDBCol>

            {/* ESTADISTICAS */}
            <MDBCol md='4'>
               <MDBCard className='mb-4'>
                  <MDBCardBody className='p-3'>
                     <MDBMedia>
                        <MDBMedia className='mr-3' href='#'>
                           <MDBMedia object src='https://mdbootstrap.com/img/Photos/Others/placeholder1.jpg' alt='' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p className='mb-2'>Tipo de cliente</p>
                           <p className='font-weight-medium mb-0'>{cliente.tipoCliente.nombre}</p>
                        </MDBMedia>
                     </MDBMedia>
                  </MDBCardBody>
               </MDBCard>
               {/* CALIFICACION */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-warning' icon='star' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <h5 className='mb-3'>Calificación de usuario</h5>
                           <Rating
                              initialRating={parseFloat(cliente.calificacion)}
                              readonly
                              emptySymbol='far fa-star fa-2x text-warning'
                              fullSymbol='fas fa-star fa-2x text-warning'
                           />
                        </MDBMedia>
                     </MDBMedia>
                  </MDBCardBody>
               </MDBCard>
               <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold mb-4 d-flex justify-content-between'>
                  <span>
                     <MDBIcon size='lg' icon='chart-pie' className='mr-3' />
                     Estadisticas
                  </span>
                  <Link className='cursor-pointer text-white' to={`/r/clientes/${clienteId}/estadisticas`}>
                     Ver más
                  </Link>
               </div>

               {/* MONEDERO */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon
                              style={{ width: '94px' }}
                              className='card-icon bg-success text-center'
                              icon='dollar-sign'
                              size='3x'
                           />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Monedero</p>
                           <h3 className='font-weight-medium mb-0'>N/A</h3>
                        </MDBMedia>
                     </MDBMedia>
                  </MDBCardBody>
               </MDBCard>
               {/* SOLICITUDES */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-warning' far icon='envelope' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Solicitudes</p>
                           <h3 className='font-weight-medium mb-0'>N/A</h3>
                        </MDBMedia>
                     </MDBMedia>

                     <div className='mt-4'>
                        <p className='font-weight-medium'>Última solicitud:</p>
                        <p>--/--/---- 00:00 PM</p>
                        <p className='font-weight-medium'>Última solicitud aceptada:</p>
                        <p>--/--/---- 00:00 PM</p>
                        <p className='font-weight-medium'>Solicitudes aceptadas:</p>
                        <p>-- Solicitudes</p>
                        <p className='font-weight-medium'>Solicitudes rechazadas:</p>
                        <p>-- Solicitudes</p>
                        <p className='font-weight-medium'>Solicitudes sin taxistas disponibles:</p>
                        <p>-- Solicitudes</p>
                        <p className='font-weight-medium'>Solicitudes que taxistas no aceptaron:</p>
                        <p>-- Solicitudes</p>
                     </div>
                  </MDBCardBody>
               </MDBCard>
               {/* VIAJES */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-primary' icon='route' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Viajes</p>
                           <h3 className='font-weight-medium mb-0'>N/A</h3>
                        </MDBMedia>
                     </MDBMedia>

                     <div className='mt-4'>
                        <p className='font-weight-medium'>Ticket promedio de viaje:</p>
                        <p>-- MXN</p>
                        <p className='font-weight-medium'>Km promedio de viaje:</p>
                        <p>-- Km.</p>
                     </div>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
         </MDBRow>
      </section>
   );
};

export default ClienteDetalles;
