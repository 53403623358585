import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from 'mdbreact';
import * as Yup from 'yup';
import { Formik, FormikProps, Field, Form, FieldArray, ArrayHelpers, ErrorMessage } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import SelectInput from 'components/shared/SelectInput';
import SwitchInput from 'components/shared/SwitchInput';
// Mis Types
import Zona from 'models/Zonas';
import { Options } from 'typings/General';

export interface CobranzaProps {
   zona: Zona;
   isZonaEdit: boolean;
   tipoVehiculos: Options[];
   onSwitchChange: (inputName, setFieldValue, tarifas, unshift, remove) => (event, checked) => Promise<void>;
   onDeleteTarifa: (idTarifa: number, idMetodoPago: number) => Promise<void>;
   onBackBtnClick: () => void;
   onStepSubmit: (values, actions) => void;
}

const getTituloTipoTarifa = (id: number) => {
   switch (id) {
      case 1:
         return 'Tarifas para efectivo';
      case 2:
         return 'Tarifas para tarjeta';
      case 3:
         return 'Tarifas para monedero';
   }
};

const Cobranza: React.FC<CobranzaProps> = ({
   zona,
   isZonaEdit,
   tipoVehiculos,
   onSwitchChange,
   onDeleteTarifa,
   onBackBtnClick,
   onStepSubmit
}) => {
   const initialValues = cloneDeep(zona);

   const cobranzaSchema = Yup.object().shape({
      pagoEfectivo: Yup.boolean(),
      pagoTarjeta: Yup.boolean(),
      tarifas: Yup.array().of(
         Yup.object().shape(
            {
               idMetodoPago: Yup.number(),
               idTipoVehiculo: Yup.number()
                  .nullable()
                  .required('Campo requerido'),
               tarifaUnica: Yup.string().when(
                  ['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'],
                  {
                     is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                        if (!tarifaUnica && (!costoKm && !costoMin && !banderazo && !tarifaMinima && !multiplicador)) return true;
                     },
                     then: Yup.string()
                        .required('Campo requerido')
                        .nullable()
                        .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                           message: 'Ingrese un formato válido. Ej: 400, 400.00',
                           excludeEmptyString: true
                        }),
                     otherwise: Yup.string()
                        .nullable()
                        .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                           message: 'Ingrese un formato válido. Ej: 400, 400.00',
                           excludeEmptyString: true
                        })
                  }
               ),
               costoKm: Yup.string().when(['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'], {
                  is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                     if (!tarifaUnica && (!costoKm || !costoMin || !banderazo || !tarifaMinima || !multiplicador)) return true;
                  },
                  then: Yup.string()
                     .required('Campo requerido')
                     .nullable()
                     .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                        message: 'Ingrese un formato válido. Ej: 400, 400.00',
                        excludeEmptyString: true
                     }),
                  otherwise: Yup.string().nullable()
               }),
               costoMin: Yup.string().when(['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'], {
                  is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                     if (!tarifaUnica && (!costoKm || !costoMin || !banderazo || !tarifaMinima || !multiplicador)) return true;
                  },
                  then: Yup.string()
                     .required('Campo requerido')
                     .nullable()
                     .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                        message: 'Ingrese un formato válido. Ej: 400, 400.00',
                        excludeEmptyString: true
                     }),
                  otherwise: Yup.string().nullable()
               }),
               banderazo: Yup.string().when(
                  ['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'],
                  {
                     is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                        if (!tarifaUnica && (!costoKm || !costoMin || !banderazo || !tarifaMinima || !multiplicador)) return true;
                     },
                     then: Yup.string()
                        .required('Campo requerido')
                        .nullable()
                        .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                           message: 'Ingrese un formato válido. Ej: 400, 400.00',
                           excludeEmptyString: true
                        }),
                     otherwise: Yup.string().nullable()
                  }
               ),
               tarifaMinima: Yup.string().when(
                  ['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'],
                  {
                     is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                        if (!tarifaUnica && (!costoKm || !costoMin || !banderazo || !tarifaMinima || !multiplicador)) return true;
                     },
                     then: Yup.string()
                        .required('Campo requerido')
                        .nullable()
                        .matches(/^\d{1,}$|^\d{1,}\.(\d{1,2})$/, {
                           message: 'Ingrese un formato válido. Ej: 400, 400.00',
                           excludeEmptyString: true
                        }),
                     otherwise: Yup.string().nullable()
                  }
               ),
               multiplicador: Yup.string().when(
                  ['tarifaUnica', 'costoKm', 'costoMin', 'banderazo', 'tarifaMinima', 'multiplicador'],
                  {
                     is: (tarifaUnica, costoKm, costoMin, banderazo, tarifaMinima, multiplicador) => {
                        if (!tarifaUnica && (!costoKm || !costoMin || !banderazo || !tarifaMinima || !multiplicador)) return true;
                     },
                     then: Yup.string()
                        .required('Campo requerido')
                        .nullable()
                        .matches(/^[1-9]{1,}$|^\d{1,}\.(\d{1,2})$/, {
                           message: 'Ingrese un formato válido. Ej: 400, 400.00',
                           excludeEmptyString: true
                        }),
                     otherwise: Yup.string().nullable()
                  }
               )
            },
            [
               ['tarifaUnica', 'tarifaUnica'],
               ['tarifaUnica', 'costoKm'],
               ['tarifaUnica', 'costoMin'],
               ['tarifaUnica', 'banderazo'],
               ['tarifaUnica', 'tarifaMinima'],
               ['tarifaUnica', 'multiplicador'],
               ['costoKm', 'costoKm'],
               ['costoKm', 'costoMin'],
               ['costoKm', 'banderazo'],
               ['costoKm', 'tarifaMinima'],
               ['costoKm', 'multiplicador'],
               ['costoKm', 'costoKm'],
               ['costoMin', 'costoMin'],
               ['banderazo', 'banderazo'],
               ['banderazo', 'costoKm'],
               ['banderazo', 'costoMin'],
               ['banderazo', 'tarifaMinima'],
               ['banderazo', 'multiplicador'],
               ['tarifaMinima', 'tarifaMinima'],
               ['tarifaMinima', 'costoKm'],
               ['tarifaMinima', 'costoMin'],
               ['tarifaMinima', 'banderazo'],
               ['tarifaMinima', 'multiplicador'],
               ['multiplicador', 'multiplicador'],
               ['multiplicador', 'costoKm'],
               ['multiplicador', 'costoMin'],
               ['multiplicador', 'banderazo'],
               ['multiplicador', 'multiplicador']
            ]
         )
      )
      // .required('Se debe seleccionar al menos 1 método de pago')
   });

   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         validationSchema={cobranzaSchema}
         onSubmit={onStepSubmit}
         render={({ values: { tarifas }, errors, setFieldValue }: FormikProps<Zona>) => (
            <Form>
               {/* {console.log(tarifas)} */}
               <FieldArray
                  name='tarifas'
                  render={({ unshift, remove }: ArrayHelpers) => (
                     <Fragment>
                        <p className='text-muted font-size-18'>Métodos de pago</p>

                        <Field
                           name='pagoEfectivo'
                           label='Pago con efectivo'
                           tarifas={tarifas}
                           unshift={unshift}
                           remove={remove}
                           onSwitchChange={onSwitchChange}
                           component={SwitchInput}
                        />
                        <Field
                           name='pagoTarjeta'
                           label='Pago con tarjeta'
                           tarifas={tarifas}
                           unshift={unshift}
                           remove={remove}
                           onSwitchChange={onSwitchChange}
                           component={SwitchInput}
                        />
                        {/* Errores del arreglo */}
                        {typeof errors.tarifas === 'string' ? <ErrorFeedback>{errors.tarifas}</ErrorFeedback> : null}

                        <hr />

                        {tarifas.map((tarifa, index) => (
                           <Fragment key={(tarifa.idTarifa as number) ? (tarifa.idTarifa as number) : tarifa.uid}>
                              <p className='text-muted mt-4 mb-1 font-size-18'>
                                 {getTituloTipoTarifa(tarifa.idMetodoPago as number)}
                              </p>

                              <MDBRow className='mb-3'>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.idTipoVehiculo`}
                                       render={({ field }) => (
                                          <SelectInput
                                             {...field}
                                             placeholder='Elegir Tipo Vehiculo'
                                             options={tipoVehiculos}
                                             isSearchable={false}
                                             setFieldValue={setFieldValue}
                                             isDisabled={true}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.idTipoVehiculo`} component={ErrorFeedback} />
                                 </MDBCol>
                                 {tarifa.idMetodoPago !== 3 && (
                                    <MDBCol className='align-self-end' md='4'>
                                       <MDBBtn
                                          className='p-2 mb-1 text-capitalize'
                                          color='danger'
                                          onClick={
                                             tarifa.idTarifa
                                                ? () => onDeleteTarifa(tarifa.idTarifa as number, tarifa.idMetodoPago)
                                                : () => {
                                                     remove(index);

                                                     const tarifasLenght = tarifas.filter(
                                                        tarifaX =>
                                                           tarifaX.idMetodoPago === tarifas[index].idMetodoPago &&
                                                           tarifaX.idTipoVehiculo !== tarifas[index].idTipoVehiculo
                                                     ).length;
                                                     if (!tarifasLenght) {
                                                        tarifa.idMetodoPago === 1 // Efectivo
                                                           ? setFieldValue('pagoEfectivo', false)
                                                           : setFieldValue('pagoTarjeta', false);
                                                     }
                                                  }
                                          }>
                                          <MDBIcon className='mr-2' icon='trash-alt' /> Eliminar
                                       </MDBBtn>
                                    </MDBCol>
                                 )}
                              </MDBRow>
                              <MDBRow className='mb-3'>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.tarifaUnica`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Tárifa única'
                                             type='text'
                                             disabled={
                                                tarifas[index].costoKm ||
                                                tarifas[index].costoMin ||
                                                tarifas[index].banderazo ||
                                                tarifas[index].tarifaMinima ||
                                                tarifas[index].multiplicador
                                                   ? true
                                                   : false
                                             }
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.tarifaUnica`} component={ErrorFeedback} />
                                 </MDBCol>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.costoKm`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Costo por kilómetro'
                                             type='text'
                                             disabled={tarifas[index].tarifaUnica}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.costoKm`} component={ErrorFeedback} />
                                 </MDBCol>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.costoMin`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Costo por minuto'
                                             type='text'
                                             disabled={tarifas[index].tarifaUnica}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.costoMin`} component={ErrorFeedback} />
                                 </MDBCol>
                              </MDBRow>

                              <MDBRow className='mb-3'>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.banderazo`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Banderazo'
                                             type='text'
                                             disabled={tarifas[index].tarifaUnica}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.banderazo`} component={ErrorFeedback} />
                                 </MDBCol>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.tarifaMinima`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Tárifa mínima'
                                             type='text'
                                             disabled={tarifas[index].tarifaUnica}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.tarifaMinima`} component={ErrorFeedback} />
                                 </MDBCol>
                                 <MDBCol md='4'>
                                    <Field
                                       name={`tarifas.${index}.multiplicador`}
                                       render={({ field }) => (
                                          <MDBInput
                                             {...field}
                                             outline
                                             label='Multiplicador'
                                             type='text'
                                             disabled={tarifas[index].tarifaUnica}
                                          />
                                       )}
                                    />
                                    <ErrorMessage name={`tarifas.${index}.multiplicador`} component={ErrorFeedback} />
                                 </MDBCol>
                              </MDBRow>
                              <hr />
                           </Fragment>
                        ))}
                     </Fragment>
                  )}
               />

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                        Atrás
                     </MDBBtn>
                  </MDBCol>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default Cobranza;
