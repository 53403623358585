import React, { useState, useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMedia, MDBIcon, MDBCollapse, MDBBadge } from 'mdbreact';
import moment from 'moment';
import Rating from 'react-rating';
import { toast } from 'react-toastify';
import polyline from '@mapbox/polyline';
import { Map, TileLayer, Polyline, Marker } from 'react-leaflet';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import { inicioMarker, finalMarker } from 'utils/icons';
import useScrollTop from 'hooks/useScrollTop';
import http from 'services/http.service';

const getEstatusBadge = estatus => {
   switch (estatus.idEstatusViaje) {
      case 1:
         return <MDBBadge color='info'>{estatus.nombre}</MDBBadge>;
      case 2:
         return <MDBBadge color='primary'>{estatus.nombre}</MDBBadge>;
      case 3:
         return <MDBBadge color='secondary'>{estatus.nombre}</MDBBadge>;
      case 4:
         return <MDBBadge color='success'>{estatus.nombre}</MDBBadge>;
      case 5:
         return <MDBBadge color='warning'>{estatus.nombre}</MDBBadge>;
      case 6:
         return <MDBBadge color='light'>{estatus.nombre}</MDBBadge>;
      case 7:
         return <MDBBadge color='default'>{estatus.nombre}</MDBBadge>;
   }
};

export interface ViajeDetalleProps extends RouteComponentProps { }

const ViajeDetalles: React.FC<ViajeDetalleProps> = ({ match }) => {
   const viajeId = match.params['id'];
   const [viaje, setViaje] = useState<any>();
   const [estadisticas, setEstadisticas] = useState<any>();
   const [collapseId, setCollapseId] = useState<string>('');

   // Mapa
   const mapa = useRef<any>(null);
   const [map, setMap] = useState<any>({
      polygonPoints: [],
      initialPoint: [],
      finalPoint: []
   });

   // SmoothScroll
   useScrollTop();

   // Obtener informacion de la zona
   useEffect(() => {
      const fetchViaje = async () => {
         try {
            const viajeData: any = await http.get(`viajes/${viajeId}`);
            setViaje(viajeData);

            // Decodificar polilinea
            let polygonPoints: number[][] = polyline.decode(viajeData.polilineaViaje.polilinea);
            polygonPoints.unshift([
               parseFloat(viajeData.polilineaViaje.dirInicio.lat),
               parseFloat(viajeData.polilineaViaje.dirInicio.lng)
            ]);
            polygonPoints.push([
               parseFloat(viajeData.polilineaViaje.dirFin.lat),
               parseFloat(viajeData.polilineaViaje.dirFin.lng)
            ]);
            // polygonPoints = [
            //    [parseFloat(viajeData.polilineaViaje.dirInicio.lat), parseFloat(viajeData.polilineaViaje.dirInicio.lng)],
            //    ...polygonPoints,
            //    [parseFloat(viajeData.polilineaViaje.dirFin.lat), parseFloat(viajeData.polilineaViaje.dirFin.lng)]
            // ];

            setMap({
               ...map,
               polygonPoints,
               initialPoint: [
                  parseFloat(viajeData.polilineaViaje.dirInicio.lat),
                  parseFloat(viajeData.polilineaViaje.dirInicio.lng)
               ],
               finalPoint: [parseFloat(viajeData.polilineaViaje.dirFin.lat), parseFloat(viajeData.polilineaViaje.dirFin.lng)]
            });
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>
                     Ha ocurrido un error al obtener los datos del viaje, intente de nuevo.
                  </ToastMessage>
               );
            }
         }
      };
      const fetchEstadisticas = async () => {
         try {
            const estadisticasData = await http.get(`viajes/${viajeId}/estadisticas`);
            setEstadisticas(estadisticasData);
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener las estadisticas, intente de nuevo.</ToastMessage>
            );
         }
      };
      fetchViaje();
      fetchEstadisticas();
      // eslint-disable-next-line
   }, [viajeId]);

   // Centrar la ruta del viaje en el mapa
   useEffect(() => {
      if (map.initialPoint.length && map.finalPoint.length) {
         mapa.current.leafletElement.fitBounds([map.initialPoint, map.finalPoint]);
      }
   }, [map.initialPoint, map.finalPoint]);

   const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ''));

   const getCosto = (viaje: any) => {
      if (viaje.pagos.length > 0 && (viaje.pagos[0].metodoPago.idMetodoPago === 1 || viaje.pagos[0].metodoPago.idMetodoPago === 3)) {
         return parseFloat(viaje.costoFinal).toFixed(2);
      }
      return parseFloat(viaje.costo).toFixed(2);
   };

   return (
      <section id='viajes'>
         <MDBRow className='mb-4'>
            <MDBCol className='align-self-center'>
               <header>
                  <h3 className='mb-0'>
                     <Link className='text-dark' to='/r/viajes'>
                        <MDBIcon className='mr-3' icon='arrow-left' />
                     </Link>
                     Detalles de viaje
                  </h3>
               </header>
            </MDBCol>
         </MDBRow>

         <MDBRow>
            <MDBCol md='8'>
               {/* INFORMACION DE VIAJE */}
               <MDBCard className='mb-4'>
                  <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold'>
                     <MDBIcon size='lg' icon='route' className='mr-3' />
                     Información del viaje
                  </div>
                  <MDBCardBody className='p-4'>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Punto de partida
                           </label>
                           <p>{viaje ? viaje.polilineaViaje.dirInicio.direccion : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Dirección de destino
                           </label>
                           <p>{viaje ? viaje.polilineaViaje.dirFin.direccion : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Método de pago
                           </label>
                           <p>{viaje && viaje.pagos.length ? viaje.pagos[0].metodoPago.nombre : 'N/A'}</p>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Hora de solicitud
                           </label>
                           <p>{viaje ? moment(viaje.peticion.createdAt).format('L LT A') : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Hora en la que se aceptó
                           </label>
                           <p>{viaje ? moment(viaje.createdAt).format('L LT A') : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Hora de llegada a punto de partida
                           </label>
                           <p>{viaje && viaje.pickUpTime ? moment(viaje.pickUpTime).format('L LT A') : 'N/A'}</p>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Hora de fin
                           </label>
                           <p>{viaje && viaje.dropTime ? moment(viaje.dropTime).format('L LT A') : 'N/A'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Calificación de chofer
                           </label>
                           <p>
                              {viaje ? (
                                 <Rating
                                    initialRating={viaje.puntosChofer ? viaje.puntosChofer.rate : 0}
                                    readonly
                                    emptySymbol='far fa-star fa-lg mr-1'
                                    fullSymbol='fas fa-star fa-lg mr-1'
                                 />
                              ) : (
                                    'N/A'
                                 )}
                           </p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Calificación de cliente
                           </label>
                           <p>
                              {viaje ? (
                                 <Rating
                                    initialRating={viaje.puntosCliente ? viaje.puntosCliente.rate : 0}
                                    readonly
                                    emptySymbol='far fa-star fa-lg mr-1'
                                    fullSymbol='fas fa-star fa-lg mr-1'
                                 />
                              ) : (
                                    'N/A'
                                 )}
                           </p>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Estado
                           </label>
                           <p>{viaje ? getEstatusBadge(viaje.estatusViaje) : null}</p>
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>

               {/* MAPA DE RECORRIDO*/}
               <MDBCard className='mb-4'>
                  <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'>
                     <span>
                        <MDBIcon size='lg' icon='map' className='mr-3' />
                        Mapa de recorrido
                     </span>
                     <span>
                        <MDBIcon size='lg' icon='minus' />
                     </span>
                  </div>

                  <MDBCardBody className='p-0'>
                     {/* MAPA */}
                     <Map ref={mapa} maxZoom={18} scrollWheelZoom={false}>
                        <TileLayer
                           attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                           url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <Polyline positions={map.polygonPoints} />
                        {!!map.polygonPoints.length && (
                           <>
                              <Marker position={map.initialPoint} title={'Inicio'} icon={inicioMarker} />
                              <Marker position={map.finalPoint} title={'Final'} icon={finalMarker} />
                           </>
                        )}
                     </Map>
                  </MDBCardBody>
               </MDBCard>

               {/* COBRO DE VIAJE */}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('cobro-viaje')}>
                     <span>
                        <MDBIcon size='lg' icon='coins' className='mr-3' />
                        Cobro de viaje
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'cobro-viaje' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='cobro-viaje' isOpen={collapseId}>
                     <MDBCardBody className='px-4 pb-4 pt-3'>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Costo por kilómetro
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `$${parseFloat(viaje.pagos[0].tarifa.costoKm).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Costo por minuto
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `$${parseFloat(viaje.pagos[0].tarifa.costoMin).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Tarifa mínima
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `$${parseFloat(viaje.pagos[0].tarifa.tarifaMinima).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Banderazo
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `$${parseFloat(viaje.pagos[0].tarifa.banderazo).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Multiplicador
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `x${parseFloat(viaje.pagos[0].tarifa.multiplicador).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Descuento aplicado
                              </label>
                              {/* TODO: Este dato esta pendiente en el backend */}
                              <p>{viaje ? '-' : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           {/* <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Otras comisiones
                              </label>
                              <p>
                                 {viaje && viaje.pagos.length
                                    ? `$${(
                                         parseFloat(viaje.pagos[0].comisionConekta) + parseFloat(viaje.pagos[0].comisionWiicab)
                                      ).toFixed(2)}`
                                    : 'N/A'}
                              </p>
                           </MDBCol> */}
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Costo de viaje
                              </label>
                              <p>{viaje ? `$${getCosto(viaje)}` : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>

               {/* DATOS CHOFER*/}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('datos-chofer')}>
                     <span>
                        <MDBIcon size='lg' icon='car-alt' className='mr-3' />
                        Datos de chofer
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'datos-chofer' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='datos-chofer' isOpen={collapseId}>
                     <MDBCardBody className='px-4 pb-4 pt-3'>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 ID de chofer
                              </label>
                              <p>{viaje ? viaje.chofer.idChofer : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Nombre
                              </label>
                              <p>
                                 {viaje
                                    ? `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Teléfono
                              </label>
                              <p>{viaje ? viaje.chofer.telefono : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Correo electrónico
                              </label>
                              <p>{viaje ? viaje.chofer.email : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Clave
                              </label>
                              <p>{viaje ? viaje.claveChofer.clave : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Unidad
                              </label>
                              <p>{viaje ? viaje.vehiculoChofer.numeroVehiculo : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Vehículo
                              </label>
                              <p>
                                 {viaje
                                    ? `${viaje.vehiculoChofer.marca.nombre}, ${viaje.vehiculoChofer.modelo.nombre}, ${viaje.vehiculoChofer.vehicleYear}`
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Placas de Vehículo
                              </label>
                              <p>{viaje ? viaje.placasVehiculo.placas : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>

               {/* DATOS CLIENTE*/}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('datos-cliente')}>
                     <span>
                        <MDBIcon size='lg' icon='id-card' className='mr-3' />
                        Datos de cliente
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'datos-cliente' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='datos-cliente' isOpen={collapseId}>
                     <MDBCardBody className='px-4 pb-4 pt-3'>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 ID de cliente
                              </label>
                              <p>{viaje ? viaje.cliente.idCliente : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Nombre
                              </label>
                              <p>{viaje ? `${viaje.cliente.nombre}` : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Correo electrónico
                              </label>
                              <p>{viaje ? viaje.cliente.email : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium' htmlFor='City'>
                                 Calificación promedio
                              </label>
                              <p>
                                 {viaje ? (
                                    <Rating
                                       initialRating={viaje.cliente.calificacion}
                                       readonly
                                       emptySymbol='far fa-star fa-lg mr-1'
                                       fullSymbol='fas fa-star fa-lg mr-1'
                                    />
                                 ) : (
                                       'N/A'
                                    )}
                              </p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>
            </MDBCol>

            {/* ESTADISTICAS */}
            <MDBCol md='4'>
               <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold mb-4'>
                  <span>
                     <MDBIcon size='lg' icon='chart-pie' className='mr-3' />
                     Estadisticas
                  </span>
               </div>
               {/* SOLICITUD */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-warning' far icon='envelope' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p className='font-size-18 mb-0'>Solicitud</p>
                        </MDBMedia>
                     </MDBMedia>

                     <div className='mt-4'>
                        <p className='font-weight-medium'>Número de taxistas que se les envío la solicitud:</p>
                        <p>{estadisticas ? estadisticas.peticion.taxistasPeticion : 'N/A'}</p>
                        <p className='font-weight-medium'>Numero de intentos del cliente:</p>
                        <p>{estadisticas ? estadisticas.peticion.intentos : 'N/A'}</p>
                        <p className='font-weight-medium'>Tiempo que tardaron en aceptar viaje:</p>
                        <p>{estadisticas ? estadisticas.peticion.tiempoAceptacion : 'N/A'}</p>
                     </div>
                  </MDBCardBody>
               </MDBCard>
               {/* VIAJE */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-primary' icon='route' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p className='font-size-18 mb-0'>Viaje</p>
                        </MDBMedia>
                     </MDBMedia>

                     <div className='mt-4'>
                        <p className='font-weight-medium'>Tiempo que tardó chofer a llegar al punto de partida:</p>
                        <p>
                           {estadisticas && estadisticas.viaje.tiempoPuntoPartida ? estadisticas.viaje.tiempoPuntoPartida : 'N/A'}
                        </p>
                        <p className='font-weight-medium'>Tiempo que tardó el chofer en iniciar el viaje:</p>
                        <p>
                           {estadisticas && estadisticas.viaje.tiempoIniciarViaje ? estadisticas.viaje.tiempoIniciarViaje : 'N/A'}
                        </p>
                        {/* <p className='font-weight-medium'>Veces que el chofer bloqueó la app:</p>
                        <p>0 veces</p>
                        <p className='font-weight-medium'>Veces que el chofer cerró la app:</p>
                        <p>0 veces</p>
                        <p className='font-weight-medium'>Tiempo aproximado fuera de linea durante el viaje:</p>
                        <p>00:00 Minutos</p> */}
                        <p className='font-weight-medium'>Km. Recorridos:</p>
                        <p>
                           {viaje && viaje.polilineaViaje.distancia
                              ? `${(parseFloat(viaje.polilineaViaje.distancia) / 1000).toFixed(2)} Km.`
                              : 'N/A'}
                        </p>
                        <p className='font-weight-medium'>Duración:</p>
                        <p>
                           {viaje && viaje.polilineaViaje.duracion
                              ? `${parseInt((parseInt(viaje.polilineaViaje.duracion) / 60).toString())} Minutos`
                              : 'N/A'}
                        </p>
                     </div>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
         </MDBRow>
      </section>
   );
};

export default ViajeDetalles;
