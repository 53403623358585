import { useEffect } from 'react';

function useScrollTop(className: string = 'right'): null {
   useEffect(() => {
      document.getElementsByClassName(className)[0].scroll({
         top: 0,
         behavior: 'smooth'
      });
   }, [className]);

   return null;
}

export default useScrollTop;
