import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import ViajesLista from 'components/viajes/ViajesLista';
import ViajesStatsGenerales from 'components/viajes/ViajesStatsGenerales';

export interface ViajesPageProps {}

const ViajesPage: React.FC<ViajesPageProps> = () => {
   return (
      <section id='viajes'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar viajes</h3>
               </header>
            </MDBCol>
         </MDBRow>

         {/* ESTADISTICAS*/}
         <ViajesStatsGenerales />

         {/* TABLA DE CHOFERES */}
         <MDBCard className='mt-3'>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <ViajesLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ViajesPage;
