import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Swal from 'sweetalert2';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import TabContainer from 'components/shared/TabContainer';
import InformacionGrupo from 'components/grupos/form/InformacionGrupo';
import AgregarChofer from 'components/grupos/form/AgregarChofer';
import GrupoResumen from 'components/grupos/form/GrupoResumen';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
// Mis Types
import { ChoferOptions, Grupo } from 'models/Grupos';
import { Option } from 'typings/General';
import { Pagination } from 'typings/Tablas';
import { mapOptionsToViewModel } from 'utils';

const InitialState: Grupo = {
   nombre: '',
   idChoferLider: null,
   idZona: null,
   choferes: []
};

export interface GrupoStepperProps extends RouteComponentProps {}

const GrupoStepperContainer: React.FC<GrupoStepperProps> = ({ match, history }) => {
   // FORM STATE
   const grupoId = match.params['id'];
   const [grupo, setGrupo] = useState<Grupo>({
      ...InitialState
   });
   const [grupoUpdate, setGrupoUpdate] = useState<Grupo>({
      ...InitialState
   });
   const [addChoferes, setAddChoferes] = useState<number[]>([]);
   const [removeChoferes, setRemoveChoferes] = useState<number[]>([]);

   // TABS
   const [tabValue, setTabValue] = useState<number>(0);

   // SELECTS
   const [choferesLideresOptions, setChoferesLideresOptions] = useState<Option[]>([]);
   const [zonasOptions, setZonasOptions] = useState<Option[]>([]);

   // TABLA
   const [choferOptions, setChoferOptions] = useState<ChoferOptions[]>([]);
   const [selectedOptions, setSelectedOptions] = useState<ChoferOptions[]>([]);
   // const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [miembros, setMiembros] = useState<any[]>([]);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   // MAPA
   const [map, setMap] = useState<any>({
      position: [29.0729673, -110.9559192],
      zoom: 7,
      polygonPoints: []
   });
   // Centrar la zona en el mapa
   const mapa = useCallback(
      mapaNode => {
         if (mapaNode !== null && map.polygonPoints.length) {
            mapaNode.leafletElement.fitBounds([map.polygonPoints]);
         }
      },
      [map]
   );
   const mapaResumen = useCallback(
      mapaResumenNode => {
         if (mapaResumenNode !== null && map.polygonPoints.length && tabValue === 2) {
            mapaResumenNode.leafletElement.fitBounds([map.polygonPoints]);
         }
      },
      // eslint-disable-next-line
      [tabValue]
   );

   // Obtener las zonas
   useEffect(() => {
      const fetchZonas = async () => {
         try {
            const params = {
               activo: true
            };
            const { rows }: any = await http.get('zonas', { params });
            setZonasOptions(mapOptionsToViewModel(rows));
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ocurrió un error al cargar la lista de zonas, intente de nuevo.</ToastMessage>
            );
         }
      };

      fetchZonas();
   }, []);

   // Centrar la zona en el mapa
   // useEffect(() => {
   //    if (map.polygonPoints.length && mapa.current) {
   //       mapa.current.leafletElement.fitBounds([map.polygonPoints]);
   //    } else if (map.polygonPoints.length && tabValue === 2) {
   //       mapaResumen.current.leafletElement.fitBounds([map.polygonPoints]);
   //    }
   // }, [map, mapa.current, tabValue]);

   // Obtener datos para editar grupo
   useEffect(() => {
      const fetchGrupo = async () => {
         try {
            const grupoData = await http.get(`grupos/${grupoId}`);
            populateGrupo(grupoData);
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>
                     Ha ocurrido un error al obtener los datos del grupo, intente de nuevo.
                  </ToastMessage>
               );
            }
         }
      };

      const populateGrupo = grupoEdit => {
         const grupoMapped = {
            nombre: grupoEdit.nombre,
            idChoferLider: grupoEdit.choferLider ? grupoEdit.choferLider.idChofer : null,
            idZona: grupoEdit.zona.idZona,
            choferes: []
         };
         setGrupo(cloneDeep(grupoMapped));
         setGrupoUpdate(cloneDeep(grupoMapped));

         // Mappear puntos del poligono
         const polygonPoints = grupoEdit.zona.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
         setMap({
            ...map,
            polygonPoints
         });
      };

      const fetchMiembros = async () => {
         const { page } = pagination;
         try {
            const params = {
               // NOTE: El limite podria cambiar
               limit: 200,
               page
            };
            const { rows: choferesList, count: totalSize }: any = await http.get(`grupos/${grupoId}/choferes`, { params });
            setMiembros(mapMiembrosToViewModel(choferesList));
            setChoferesLideresOptions(mapChoferesLiderOptionsToViewModel(choferesList));
            setPagination({ ...pagination, totalSize });
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener la lista de miembros, intente de nuevo.</ToastMessage>
            );
         }
      };

      if (grupoId) {
         fetchGrupo();
         fetchMiembros();
      }
   }, [grupoId]);

   const fetchZona = async (id: number) => {
      try {
         const zona: any = await http.get(`zonas/${id}`);
         // Mappear puntos del poligono
         const polygonPoints = zona.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
         setMap({
            ...map,
            polygonPoints
         });
      } catch (error) {
         toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar el área de la zona.</ToastMessage>);
      }
   };

   // SELECT
   const mapChoferesLiderOptionsToViewModel = (options: any[]): Option[] => {
      return options.map(option => {
         return {
            value: option[Object.keys(option)[0]],
            label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`
         };
      });
   };

   // TABLA DE MIEMBROS
   const handleAddMiembro = () => {
      if (selectedOptions.length) {
         const choferExist = miembros.find(chofer => chofer.idChofer === selectedOptions[0].idChofer);
         if (choferExist) {
            toast.warn(<ToastMessage type={'warn'}>El usuario ya es parte del grupo.</ToastMessage>);
            return;
         }
         if (grupoId) {
            // Checar si habia sido removido y lo es tan volviendo a agregar
            const wasRemovedBeforeAdd = removeChoferes.find(idChofer => idChofer === selectedOptions[0].idChofer);
            if (wasRemovedBeforeAdd) {
               setRemoveChoferes(removeChoferes.filter(idChofer => idChofer !== selectedOptions[0].idChofer));
            }
            // Agregar el chofer al state para actualizar grupo
            setAddChoferes(addChoferes.concat(selectedOptions[0].idChofer as number));
         } else {
            // Agregar el chofer al state de update
            setGrupo({
               ...grupo,
               choferes: grupo.choferes.concat(selectedOptions[0].idChofer as number)
            });
         }
         // Agregar el chofer al state de la tabla
         const miembro = mapMiembroToViewModel(selectedOptions[0]);
         const newMiembros = miembros.concat(miembro);
         setMiembros(newMiembros);
         // Actualizar las opciones de lideres
         setChoferesLideresOptions(
            choferesLideresOptions.concat({
               value: selectedOptions[0].idChofer,
               label: `${selectedOptions[0].nombres} ${selectedOptions[0].primerApellido} ${selectedOptions[0].segundoApellido}`
            } as Option)
         );
      }
      return;
   };

   const handleRemoveMiembro = async (id: number) => {
      if (grupoId) {
         // Checar si habia agregado y lo eliminaron localmente
         const wasAddedBeforeDelete = addChoferes.find(idChofer => idChofer === id);
         if (wasAddedBeforeDelete) {
            setAddChoferes(addChoferes.filter(idChofer => idChofer !== id));
            // Actualizar el state local de la tabla
            const newMiembros = miembros.filter(miembro => miembro.idChofer !== id);
            setMiembros(newMiembros);
            // Actualizar las opciones de lideres
            const newChoferLideresOptions = choferesLideresOptions.filter(chofer => chofer.value !== id);
            setChoferesLideresOptions(newChoferLideresOptions);
            return;
         }
         // Actualizar el state de los choferes que se eliminaran del grupo
         setRemoveChoferes(removeChoferes.concat(id));
         // Checar si se elimino el lider del grupo
         if (grupo.idChoferLider) {
            // Buscar en los choferes que se van a agregar
            const liderFound1 = addChoferes.find(idChofer => idChofer === grupo.idChoferLider);
            // Buscar en los choferes que ya se encuentran guardados en la BD
            const liderFound2 = miembros.find(chofer => chofer.idChofer === grupo.idChoferLider);
            // Si se encontro en los miembros nuevos o en los antiguos, entonces se marca como null
            if (liderFound1 || liderFound2) {
               setGrupo({
                  ...grupo,
                  idChoferLider: null
               });
            }
         }
      }

      // Actualizar el state de la tabla
      const newMiembros = miembros.filter(miembro => miembro.idChofer !== id);
      setMiembros(newMiembros);

      // Actualizar las opciones de lideres
      const newChoferLideresOptions = choferesLideresOptions.filter(chofer => chofer.value !== id);
      setChoferesLideresOptions(newChoferLideresOptions);
   };

   const handleSelectedMiembro = (selected: ChoferOptions[]) => {
      setSelectedOptions(selected);
   };

   const fetchSearchChoferes = async (query: string) => {
      const { page, sizePerPage: limit } = pagination;
      try {
         const params = {
            ...(query.trim() ? { search: query.trim() } : {}),
            hasGroup: false,
            activo: true,
            idZona: grupo.idZona,
            limit,
            page
         };
         const { rows: choferesList, count: totalSize }: any = await http.get('choferes', { params });
         setChoferOptions(choferesList);
         setPagination({ ...pagination, totalSize });
      } catch (error) {
         toast.error(<ToastMessage type={'error'}>Ocurrió un error al buscar usuarios, intente de nuevo.</ToastMessage>);
      }
   };

   const mapMiembroToViewModel = (chofer: ChoferOptions) => {
      return {
         idChofer: chofer.idChofer,
         nombre: `${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}`,
         telefono: chofer.telefono,
         email: chofer.email
      };
   };

   const mapMiembrosToViewModel = (choferes: ChoferOptions[]) => {
      return choferes.map(chofer => {
         return {
            idChofer: chofer.idChofer,
            nombre: `${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}`,
            telefono: chofer.telefono,
            email: chofer.email
         };
      });
   };

   const handleTableChange = (type, { page, sizePerPage }) => {
      setPagination({
         ...pagination,
         page
      });
   };

   // BUTTONS HANDLERS
   const handleBackBtnClick = () => setTabValue(tabValue => tabValue - 1);

   const handleStepSubmit = (values: Grupo) => {
      setGrupo({
         ...grupo,
         ...values
      });
      setTabValue(tabValue => tabValue + 1);
   };

   const handleFormSubmit = async () => {
      try {
         grupoId ? await updateGrupo() : await createGrupo();
      } catch (error) {
         apiErrorHandler('Grupo', error);
      }
   };

   const updateGrupo = async () => {
      const body = setBody();
      await http.put(`grupos/${grupoId}`, body);
      const result = await Swal.fire({
         title: 'Datos actualizados',
         text: 'Los cambios han sido actualizados correctamente.',
         type: 'success',
         showCancelButton: false,
         confirmButtonText: 'Aceptar',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-info waves-effect waves-light text-capitalize'
         },
         buttonsStyling: false
      });
      if (result) {
         history.push(`/r/grupos`);
      }
   };

   const createGrupo = async () => {
      const body = {
         nombre: grupo.nombre,
         ...(grupo.idChoferLider && { idChoferLider: grupo.idChoferLider }),
         idZona: grupo.idZona,
         ...(grupo.choferes.length && { choferes: grupo.choferes })
      };
      const newGrupo: any = await http.post('grupos', body);
      const result = await Swal.fire({
         title: 'Grupo guardado con exito!',
         text: 'El grupo ha sido guardado.',
         type: 'success',
         showCancelButton: true,
         confirmButtonText: 'Crear otro Grupo',
         cancelButtonText: 'Ver detalles',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-info waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-info waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         // Ir al paso 1
         setTabValue(0);
         // Reset form
         setGrupo({
            ...InitialState
         });
         setMiembros([]);
         setMap({ polygonPoints: [] });
         return;
      }
      history.push(`/r/grupos/${newGrupo.idGrupo}/detalles`);
   };

   const setBody = () => {
      const body = {};
      const grupoAfter = cloneDeep(grupo);
      const grupoBefore = cloneDeep(grupoUpdate);
      delete grupoAfter.choferes;
      delete grupoBefore.choferes;

      for (const [key, value] of Object.entries(grupoAfter)) {
         if (value !== grupoBefore[key]) {
            // Checar si se elimino el chofer lider
            if (key === 'idChoferLider' && value === null) {
               continue;
            }
            body[key] = value;
         }
      }
      if (addChoferes.length) {
         body['addChoferes'] = addChoferes;
      }
      if (removeChoferes.length) {
         body['removeChoferes'] = removeChoferes;
      }
      return body;
   };

   if (grupoId && (!grupo.idZona || !zonasOptions.length)) {
      return null;
   }
   return (
      <section id='grupos'>
         <header className='mb-4'>
            <h3 className='mb-0'>
               <Link className='text-dark' to='/r/grupos'>
                  <MDBIcon className='mr-3' icon='arrow-left' />
               </Link>
               {grupoId ? 'Editar grupo' : 'Nuevo grupo'}
            </h3>
         </header>

         <MDBCard>
            <MDBCardBody>
               <Tabs
                  classes={{
                     indicator: '#EB6C40'
                  }}
                  scrollButtons='auto'
                  variant='fullWidth'
                  value={tabValue}>
                  <Tab label='1. Información de grupo' />
                  <Tab label='2. Agregar choferes' />
                  <Tab label='3. Resumen' />
               </Tabs>
               <div className='p-4'>
                  {tabValue === 0 && (
                     <TabContainer>
                        <InformacionGrupo
                           ref={mapa}
                           grupo={grupo}
                           zonas={zonasOptions}
                           map={map}
                           fetchZona={fetchZona}
                           onStepSubmit={handleStepSubmit}
                        />
                     </TabContainer>
                  )}
                  {tabValue === 1 && (
                     <TabContainer>
                        <AgregarChofer
                           idChoferLider={grupo.idChoferLider}
                           choferesLideres={choferesLideresOptions}
                           miembros={miembros}
                           choferes={choferOptions}
                           onAddMiembro={handleAddMiembro}
                           onRemoveMiembro={handleRemoveMiembro}
                           // isTableLoading={isTableLoading}
                           pagination={pagination}
                           onTableChange={handleTableChange}
                           fetchSearchChoferes={fetchSearchChoferes}
                           onSelectOption={handleSelectedMiembro}
                           onBackBtnClick={handleBackBtnClick}
                           onStepSubmit={handleStepSubmit}
                        />
                     </TabContainer>
                  )}
                  {tabValue === 2 && (
                     <TabContainer>
                        <GrupoResumen
                           ref={mapaResumen}
                           grupo={grupo}
                           map={map}
                           miembros={miembros}
                           choferesLideres={choferesLideresOptions}
                           zonas={zonasOptions}
                           onBackBtnClick={handleBackBtnClick}
                           onFormSubmit={handleFormSubmit}
                        />
                     </TabContainer>
                  )}
               </div>
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default GrupoStepperContainer;
