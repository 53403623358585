import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MDBIcon, MDBBtn } from 'mdbreact';
import Tooltip from 'components/shared/Tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './ZonasTabla.css';

export interface ZonasTablaProps {
   zonas: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   handleToggleEstado: (id: number, activo: boolean, index: number) => Promise<void>;
}

const ZonasTabla: React.SFC<ZonasTablaProps> = ({ zonas, isTableLoading, pagination, handleTableChange, handleToggleEstado }) => {
   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <>
               {row.estado && (
                  <Tooltip title='Editar' placement='top'>
                     <Link
                        className='btn btn-indigo btn-sm my-0 mr-2 detalles-font-size text-capitalize'
                        to={`/r/zonas/${row.idZona}/editar`}>
                        <MDBIcon className='' icon='edit' size='lg' />
                     </Link>
                  </Tooltip>
               )}
               <Tooltip title='Desactivar/Activar' placement='top'>
                  <MDBBtn
                     color={row.estado ? 'success' : 'danger'}
                     className='btn btn-sm my-0 mr-2 btn-action'
                     onClick={() => handleToggleEstado(row.idZona, row.estado, rowIndex)}>
                     <MDBIcon size='2x' icon='power-off' />
                  </MDBBtn>
               </Tooltip>
               <Link
                  className='btn btn-primary btn-sm mr-2 detalles-font-size text-capitalize'
                  to={`/r/zonas/${row.idZona}/detalles`}>
                  Ver más
               </Link>
            </>
         );
      },
      [handleToggleEstado]
   );

   const columns = useMemo(() => {
      return [
         {
            dataField: 'nombreZona',
            text: 'Nombre de Zona',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'nombreAdmin',
            text: 'Administrador',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'totalChoferes',
            text: 'No. Choferes',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'options',
            text: 'Opciones',
            isDummyField: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter
         }
      ];
   }, [optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='zonas-table'
         keyField='idZona'
         data={zonas}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(ZonasTabla);
