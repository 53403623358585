import Swal from 'sweetalert2';
import logger from './logger.service';

// const choferErrors = ['Chofer/EmailExists', 'Chofer/PhoneNumberExists', 'Chofer/ClaveExists', 'Chofer/VehicleNumberExists'];
// const grupoErrors = [
//    'Grupo/ZonaNotFound',
//    'Grupo/LiderNotFound',
//    'Grupo/LiderIsDisabled',
//    'Grupo/ChoferesNotSelected',
//    'Grupo/GrupoDisabled',
//    'Grupo/LiderNotFromZone'
// ];
// const zonaErrors = [
//    'Zona/AdminNotFound',
//    'Zona/AdminIsDisabled',
//    'Zona/PlygonExists',
//    'Zona/AdminAlreadyAssigned',
//    'Zona/TipoUsuarioForbidden',
//    'Zona/AdminAlreadyAssigned'
// ];
// const usuarioErrors = ['User/PhoneNumberExists', 'User/EmailExists', 'User/ZonaNotFound', 'User/ZoneAdmin'];
// const configErrors = ['Config/AccessForbidden'];

type Entity = 'Chofer' | 'Grupo' | 'Zona' | 'Usuario' | 'Config' | 'Server' | 'Viaje';
/**
 * Function para personalizar los errores provenientes de los endpoints.
 * @param {Entity} entity
 * @param {*} error
 */
export default function apiErrorHandler(entity: Entity, error) {
   if ((error.response && error.response.status !== 500) || error.type) {
      switch (entity) {
         case 'Chofer':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Grupo':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Zona':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Usuario':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Config':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Viaje':
            Swal.fire('Error!', error.message, 'error');
            break;
         default: {
            // Enviar error a sentry
            logger.log(error.response);
            // Mostrar error generico al usuario
            Swal.fire(
               'Ocurrió un error inesperado',
               'Espere un momento e intente de nuevo. Reporte este problema a un administrador.',
               'error'
            );
            break;
         }
      }
   }
}
