import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from 'mdbreact';
import { toast } from 'react-toastify';
// Mis Componentes
import QuejasTabla from './QuejasTabla';
import ToastMessage from 'components/shared/ToastMessage';
import SelectInput from 'components/shared/SelectInput';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
// Mis Types
import { Filters, Pagination } from 'typings/Tablas';
import { Option } from 'typings/General';

const calificacionOptions: Option[] = [
   { value: 'asc', label: 'Menor' },
   { value: 'desc', label: 'Mayor' }
];
export interface QuejasListaProps { }

const QuejasLista: React.FC<QuejasListaProps> = () => {
   const [quejas, setQuejas] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [filters, setFilters] = useState<Filters>({
      calificacionChofer: null,
      calificacionCliente: null
   });

   // TABLA
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   useEffect(() => {
      fetchQuejas();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page, filters.calificacionChofer, filters.calificacionCliente]);

   const fetchQuejas = async () => {
      const { calificacionChofer, calificacionCliente } = filters;
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            ...(calificacionChofer && { calificacionChofer }),
            ...(calificacionCliente && { calificacionCliente }),
            limit,
            page
         };
         const { rows: quejasList, count: totalSize }: any = await http.get('quejas', { params });
         setQuejas(mapQuejasToViewModel(quejasList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         if ((error.status && error.status !== 500) || error.type) {
            toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de quejas.</ToastMessage>);
         }
      }
   };

   const mapQuejasToViewModel = (quejas: any[]) => {
      return quejas.map(queja => {
         return {
            idQueja: queja.idQueja,
            calChofer: queja.viaje.puntosChofer ? parseFloat(queja.viaje.puntosChofer.rate) : 0,
            calCliente: queja.viaje.puntosCliente ? parseFloat(queja.viaje.puntosCliente.rate) : 0,
            estatus: queja.estadoQueja.idEstatusQueja
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => fetchQuejas();

   const handleCleanFilters = () => {
      setSearch('');
      setFilters({
         calificacionChofer: null,
         calificacionCliente: null
      });
   };

   const handleChangeSelect = inputName => (option, { action }) => {
      setFilters({
         ...filters,
         [inputName]: option.value
      });
   };

   const handleTableChange = useCallback(
      (type, { page, sizePerPage }) => {
         setPagination({
            ...pagination,
            page
         });
      },
      [pagination]
   );

   return (
      <Fragment>
         <div className='table-filters py-2'>
            <MDBRow className=''>
               <MDBCol md='9'>
                  <MDBInput
                     className='m-0'
                     label='Buscar por nombre, correo, teléfono, ID...'
                     outline
                     icon='search'
                     iconSize='lg'
                     onChange={handleSearchChange}
                     value={search}
                  />
               </MDBCol>
               <MDBCol md='3'>
                  <div style={{ marginTop: '0.6rem' }}>
                     <Tooltip title='Actualizar' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                           <MDBIcon size='2x' icon='sync' fixed />
                        </MDBBtn>
                     </Tooltip>
                     <Tooltip title='Limpiar Filtros' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                           <MDBIcon size='2x' icon='eraser' fixed />
                        </MDBBtn>
                     </Tooltip>
                  </div>
               </MDBCol>
            </MDBRow>

            {/* FILTROS */}
            <MDBRow className='mb-3 mt-0 mx-0'>
               <MDBCol className='pl-0' md='4'>
                  <SelectInput
                     name='calificacionChofer'
                     placeholder='Calificación chofer'
                     options={calificacionOptions}
                     handleCustomSelect={handleChangeSelect}
                     value={filters.calificacionChofer}
                  />
               </MDBCol>
               <MDBCol className='pl-0' md='4'>
                  <SelectInput
                     name='calificacionCliente'
                     placeholder='Calificación cliente'
                     options={calificacionOptions}
                     handleCustomSelect={handleChangeSelect}
                     value={filters.calificacionCliente}
                  />
               </MDBCol>
            </MDBRow>
         </div>

         <QuejasTabla
            quejas={quejas}
            isTableLoading={isTableLoading}
            pagination={pagination}
            handleTableChange={handleTableChange}
         />
      </Fragment>
   );
};

export default QuejasLista;
