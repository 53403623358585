import React, { Fragment, useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBBadge, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { toast } from 'react-toastify';
import moment from 'moment';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import SelectInput from 'components/shared/SelectInput';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
import { mapOptionsToViewModel } from 'utils';
// Mis Types
import { Filters, Pagination } from 'typings/Tablas';
import { Option } from 'typings/General';

export interface HistorialViajesListaProps {
   idCliente: number;
}

const HistorialViajesLista: React.FC<HistorialViajesListaProps> = ({ idCliente }) => {
   const [viajes, setViajes] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [metodoPagoOptions, setMetodoPagoOptions] = useState<Option[]>([]);
   const [filters, setFilters] = useState<Filters>({
      metodoPago: null
   });
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   const metodoPagoFormatter = estatus => {
      if (estatus) {
         return <MDBBadge color='success px-4'>Activo</MDBBadge>;
      }
      return <MDBBadge color='danger'>Lorem ipsum</MDBBadge>;
   };
   const columns = [
      {
         dataField: 'idViaje',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'fecha',
         text: 'Fecha de viaje',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombreChofer',
         text: 'Chofer',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'monto',
         text: 'Monto',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'metodo',
         text: 'Método de pago',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: metodoPagoFormatter
      }
   ];

   useEffect(() => {
      fetchViajes();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page, filters.metodoPago]);

   const fetchViajes = async () => {
      // const { metodoPago } = filters;
      try {
         setIsTableLoading(true);

         const params = {
            order: 'desc',
            idCliente
            // ...(metodoPago ? { idMetodoPago: metodoPago} : {}) TODO: filtro pendiente
         };
         const { rows } = await http.get(`viajes`, { params });
         setViajes(mapViajesToViewModel(rows));

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         toast.error(<ToastMessage type={'error'}>Ha ocurrido un error al obtener los viajes, intente de nuevo.</ToastMessage>);
      }
   };

   useEffect(() => {
      const fetchFiltros = async () => {
         try {
            const { rows }: any = await http.get('catalogos/MetodosPago');
            setMetodoPagoOptions(mapOptionsToViewModel(rows));
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar el filtro, recargue la página.</ToastMessage>);
         }
      };

      fetchFiltros();
   }, []);

   const mapViajesToViewModel = (viajes: any[]): any[] => {
      return viajes.map(viaje => {
         return {
            idViaje: viaje.idViaje,
            fecha: moment(viaje.createdAt).format('L LT A'),
            nombreChofer: `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`,
            monto: parseFloat(viaje.costo).toFixed(2),
            metodoPago: ''
            // metodoPago: viaje.metodoPago.nombre // TODO: pendiente obtener este dato
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchViajes();
   };

   const handleCleanFilters = () => {
      setSearch('');
      setFilters({
         metodoPago: null
      });
   };

   const handleChangeSelect = inputName => (option, { action }) => {
      setFilters({
         ...filters,
         [inputName]: option.value.toString()
      });
   };

   const handleTableChange = (type, { page, sizePerPage }) => {
      setPagination({
         ...pagination,
         page
      });
   };

   return (
      <Fragment>
         <div className='table-filters py-2'>
            <MDBRow className=''>
               <MDBCol md='9'>
                  <MDBInput
                     className='m-0'
                     label='Buscar por nombre, teléfono, correo electrónico'
                     outline
                     icon='search'
                     iconSize='lg'
                     onChange={handleSearchChange}
                     value={search}
                  />
               </MDBCol>
               <MDBCol md='3'>
                  <div style={{ marginTop: '0.6rem' }}>
                     <Tooltip title='Actualizar' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                           <MDBIcon size='2x' icon='sync' fixed />
                        </MDBBtn>
                     </Tooltip>
                     <Tooltip title='Limpiar Filtros' placement='top'>
                        <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                           <MDBIcon size='2x' icon='eraser' fixed />
                        </MDBBtn>
                     </Tooltip>
                  </div>
               </MDBCol>
            </MDBRow>

            {/* FILTROS */}
            <MDBRow className='mb-3 mt-0 mx-0'>
               <MDBCol className='pl-0' md='5'>
                  <SelectInput
                     name='metodoPago'
                     placeholder='Método pago'
                     options={metodoPagoOptions}
                     isSearchable={false}
                     handleCustomSelect={handleChangeSelect}
                     value={filters.metodoPago}
                  />
               </MDBCol>
            </MDBRow>
         </div>
         <BootstrapTable
            bootstrap4
            condensed
            classes='historial-viajes-table'
            keyField='idViaje'
            data={viajes}
            columns={columns}
            remote={{
               pagination: true
            }}
            loading={isTableLoading}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            hover
            bordered={false}
            noDataIndication='No se encontraron registros.'
            rowClasses={'font-weight-bold'}
            pagination={paginationFactory({
               page: pagination.page, // Specify the current page.
               totalSize: pagination.totalSize, // Total data size.
               sizePerPage: pagination.sizePerPage, // Specify the size per page.
               withFirstAndLast: false, // hide the going to first and last page button
               alwaysShowAllBtns: true, // always show the next and previous page button
               prePageText: 'Anterior',
               nextPageText: 'Siguiente',
               hideSizePerPage: true, // hide the size per page dropdown
               hidePageListOnlyOnePage: true
            })}
            onTableChange={handleTableChange}
         />
      </Fragment>
   );
};

export default React.memo(HistorialViajesLista);
