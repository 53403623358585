import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { MDBInput, MDBBtn, MDBIcon, MDBAlert } from 'mdbreact';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { Alert } from 'typings/General';

const ForgotPassword: React.FC = () => {
   const [errorAlert, setErrorAlert] = useState<Alert>({
      isOpen: false,
      message: ''
   });
   const [successAlert, setSuccessAlert] = useState<Alert>({
      isOpen: false,
      message: ''
   });
   const initialValues = {
      email: ''
   };

   const forgotSchema = Yup.object().shape({
      email: Yup.string()
         .email('Escriba un email válido')
         .required('Campo requerido')
   });

   const handleFormSubmit = async (values, actions) => {
      try {
         await http.post('auth/forgot', {
            email: values.email
         });
         setSuccessAlert({
            isOpen: true,
            message: 'Por favor, revisa tu bandeja de correos para más instrucciones.'
         });
         actions.setSubmitting(false);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/AccountNotFound') {
            setErrorAlert({
               isOpen: true,
               message: 'El correo introducido no existe.'
            });
         }
      }
   };

   const handleCloseAlert = () => {
      if (errorAlert.isOpen) {
         setErrorAlert({
            isOpen: false,
            message: ''
         });
      } else if (successAlert.isOpen) {
         setSuccessAlert({
            isOpen: false,
            message: ''
         });
      }
   };

   return (
      <Fragment>
         <Link className='text-dark header-link' to='/login'>
            <MDBIcon icon='arrow-left' className='mr-3' />
            Recuperar contraseña
         </Link>
         <p className='mt-4'>Enviaremos un enlace a tu correo para que crees una nueva contraseña</p>
         {errorAlert.isOpen ? (
            <MDBAlert color='danger' dismiss onClose={handleCloseAlert}>
               {errorAlert.message}
            </MDBAlert>
         ) : null}
         {successAlert.isOpen ? <MDBAlert color='success'>{successAlert.message}</MDBAlert> : null}
         <Formik
            initialValues={initialValues}
            validationSchema={forgotSchema}
            onSubmit={handleFormSubmit}
            render={({ isSubmitting }: FormikProps<any>) => (
               <Form className='login-form'>
                  <Field
                     name='email'
                     render={({ field }) => <MDBInput icon='at' outline label='Correo electrónico' type='text' {...field} />}
                  />
                  <ErrorMessage name='email' component={ErrorFeedback} />

                  <MDBBtn color='default' type='submit' className='btn-login mt-4' block disabled={isSubmitting}>
                     {isSubmitting ? <MDBIcon icon='spinner' pulse size='lg' /> : 'Enviar correo'}
                  </MDBBtn>
               </Form>
            )}
         />
      </Fragment>
   );
};

export default ForgotPassword;
