import React, { useMemo } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { FieldProps } from 'formik';
import placeholderImage from 'assets/images/usuario-placeholder.jpg';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
export interface FileInputProps extends FieldProps {
   imgSrc: any;
   setImgSrc: any;
}

const FileInput: React.FC<FileInputProps> = ({ field: { name, value }, form: { setFieldValue } }) => {
   let imageSrc = useMemo(() => {
      if (typeof value === 'string') {
         return value;
      } else if (value) {
         return URL.createObjectURL(value);
      } else {
         return undefined;
      }
   }, [value]);

   const handleFileInputChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      if (input.files) {
         const file = input.files[0];
         if (SUPPORTED_FORMATS.includes(file.type)) {
            let img = new Image();
            img.onload = function({ target }) {
               // if (target) {
               //    let imageTarget: any = target;
               //    file['width'] = imageTarget.naturalWidth;
               //    file['height'] = imageTarget.naturalHeight;
               setFieldValue(name, file);
               // }
            };
            img.src = URL.createObjectURL(file);
         } else {
            setFieldValue(name, file);
         }
      }
   };

   return (
      <>
         <p className='mt-4 mb-0'>Foto de perfil</p>
         <label className='btn btn-default Ripple-parent font-weight-medium mx-0' htmlFor='imgPerfil'>
            <i className='fas fa-image fa-lg mr-2' /> Elegir archivo
         </label>
         <input
            name={name}
            id='imgPerfil'
            className='d-none'
            type='file'
            accept='.png,.jpeg,.jpg'
            onChange={handleFileInputChange}
         />
         <MDBRow className='mt-3'>
            <MDBCol md='2'>
               <img id='foto-thumbnail' className='img-fluid' src={imageSrc ? imageSrc : placeholderImage} alt='Foto de Chofer' />
            </MDBCol>
         </MDBRow>
      </>
   );
};

export default FileInput;
