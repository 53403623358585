import React, { memo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './RegistrosTabla.css';

const columns = [
   {
      dataField: 'fechaRegistro',
      text: 'Fecha',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'generadoPor',
      text: 'Generado por',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'descripcion',
      text: 'Descripcion',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   }
];

export interface RegistrosTablaProps {
   registros: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
}

const RegistrosTabla: React.SFC<RegistrosTablaProps> = ({ registros, isTableLoading, pagination, handleTableChange }) => {
   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='registros-table'
         keyField='idRegistro'
         data={registros}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(RegistrosTabla);
