import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBCollapse, MDBBadge, MDBMedia } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import useScrollTop from 'hooks/useScrollTop';
import http from 'services/http.service';
import { getDiaCorte } from 'utils';

interface Estadisticas {
   viajes: {
      totalPedidos: number;
      finalizados: string;
      endCash: string;
      endCard: string;
      endMonedero: string;
      totalOdometro: number;
   };
   peticiones?: {
      total: number;
      aceptadas: string;
      ignoradas: string;
      rechazadas: string;
   };
   horasConexion?: string;
   cancelaciones: {
      peticionesCanceladasCliente: string;
      viajesCancelados: string;
      cancelacionesChofer: string;
      cancelacionesCliente: string;
      cancelacionesAdmin: string;
   };
}

export interface ChoferDetallesProps extends RouteComponentProps {}

const ChoferDetalles: React.FC<ChoferDetallesProps> = ({ match }) => {
   const choferId = match.params['id'];
   const [chofer, setChofer] = useState<any>();
   const [vehiculos, setVehiculos] = useState<any[]>([]);
   const [estadisticas, setEstadisticas] = useState<Estadisticas>({
      viajes: {
         totalPedidos: 0,
         finalizados: '-',
         endCash: '-',
         endCard: '-',
         endMonedero: '-',
         totalOdometro: 0
      },
      peticiones: {
         total: 0,
         aceptadas: '-',
         ignoradas: '-',
         rechazadas: '-'
      },
      horasConexion: '-',
      cancelaciones: {
         peticionesCanceladasCliente: '-',
         viajesCancelados: '-',
         cancelacionesChofer: '-',
         cancelacionesCliente: '-',
         cancelacionesAdmin: '-'
      }
   });
   const [collapseId, setCollapseId] = useState<string>('');

   const columns = [
      {
         dataField: 'idVehiculo',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'numeroVehiculo',
         text: 'No. Unidad',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'marca.nombre',
         text: 'Marca',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'modelo.nombre',
         text: 'Modelo',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'placas[0].placas',
         text: 'Placas',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      }
   ];

   // SmoothScroll
   useScrollTop();

   // Obtener datos del chofer
   const fetchChofer = useCallback(async id => {
      try {
         const choferDetalles: any = await http.get(`choferes/${id}`);
         setChofer(choferDetalles);
         setVehiculos(choferDetalles.vehiculo);
      } catch (error) {
         if (error.type === 'Chofer/ChoferNotFound') {
            toast.error(<ToastMessage type={'error'}>{error.message}</ToastMessage>);
            return;
         }
         toast.error(
            <ToastMessage type={'error'}>Ha ocurrido un error al obtener los datos del chofer, intente de nuevo.</ToastMessage>
         );
      }
   }, []);

   const fetchEstadisticas = useCallback(async id => {
      try {
         const estadisticasData: any = await http.get(`choferes/${id}/estadisticas`);
         setEstadisticas(estadisticasData);
      } catch (error) {
         toast.error(
            <ToastMessage type={'error'}>Ha ocurrido un error al obtener las estadisticas, intente de nuevo.</ToastMessage>
         );
      }
   }, []);

   useEffect(() => {
      fetchEstadisticas(choferId);
      fetchChofer(choferId);
   }, [choferId, fetchChofer, fetchEstadisticas]);

   const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ''));

   const handleAplicarCargo = () => {
      // TODO: Abrir modal
   };

   const handleCastigarChofer = async () => {
      const result = await Swal.fire({
         title: `¿Estas seguro que deseas ${chofer.castigado ? 'perdonar' : 'castigar'} al chofer?`,
         text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         try {
            await http.put(`choferes/${choferId}/${chofer.castigado ? 'indultar' : 'castigar'}`);
            setChofer({
               ...chofer,
               castigado: !chofer.castigado
            });
            toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
         }
      }
   };

   const handleToggleEstado = async () => {
      const result = await Swal.fire({
         title: `¿Estás seguro que deseas ${chofer.activo ? 'desactivar' : 'activar'} al chofer?`,
         text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false,
         ...(!chofer.activo && {
            html: `<label style="float:left">Prefijo: <strong>${chofer.zona.prefijoChofer}</strong></label>`,
            input: 'text',
            inputPlaceholder: 'Código clave'
         })
         // inputValue: inputValue,
      });
      if (result.value) {
         try {
            await http.put(`choferes/${choferId}/${chofer.activo ? 'desactivar' : 'activar'}`, {
               prefijoClave: chofer.zona.prefijoChofer,
               codigoClave: result.value
            });
            setChofer({
               ...chofer,
               activo: !chofer.activo
            });
            fetchChofer(choferId);
            fetchEstadisticas(choferId);
            toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>{error.message || 'Ha ocurrido un error, intente de nuevo.'}</ToastMessage>);
         }
      }
   };

   const handleLiberarChofer = async () => {
      const result = await Swal.fire({
         title: `¿Estás seguro que deseas liberar al chofer?`,
         text:
            'Si el chofer se encuentra dentro de o fue parte de un viaje con problemas, esta acción lo regresa a su estado base.',
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Aceptar',
         cancelButtonText: 'Cancelar',
         customClass: {
            confirmButton: 'btn btn-success waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-danger waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         try {
            await http.get(`cache/choferes/${choferId}`, {
               params: {
                  resetviaje: true
               }
            });
            fetchEstadisticas(choferId);
            fetchChofer(choferId);
            toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
         }
      }
   };

   return (
      <section id='choferes'>
         <MDBRow className='mb-4'>
            <MDBCol className='align-self-center'>
               <header>
                  <h3 className='mb-0'>
                     <Link className='text-dark' to='/r/choferes'>
                        <MDBIcon className='mr-3' icon='arrow-left' />
                     </Link>
                     Detalles del chofer
                  </h3>
               </header>
            </MDBCol>
            <MDBCol className='text-right'>
               <MDBBtn color='warning' className='text-capitalize btn-top-action' onClick={handleLiberarChofer}>
                  <MDBIcon className='mr-2' icon='exclamation-circle' size='lg' />
                  Liberar
               </MDBBtn>
               <MDBBtn color='info' className='text-capitalize btn-top-action' onClick={handleAplicarCargo}>
                  <MDBIcon className='mr-2' icon='dollar-sign' size='lg' />
                  Aplicar cargo
               </MDBBtn>
               {chofer && chofer.activo && (
                  <Link to={`/r/choferes/${choferId}/editar`}>
                     <MDBBtn color='indigo' className='text-capitalize btn-top-action'>
                        <MDBIcon className='mr-2' icon='edit' size='lg' />
                        Editar
                     </MDBBtn>
                  </Link>
               )}
               <MDBBtn
                  color={chofer && chofer.castigado ? 'warning' : 'blue-grey'}
                  className='text-capitalize btn-top-action'
                  onClick={handleCastigarChofer}>
                  <MDBIcon className='mr-2' icon='exclamation-triangle' size='lg' />
                  {chofer && chofer.castigado ? 'Castigado' : 'Castigar'}
               </MDBBtn>
               <MDBBtn
                  color={chofer && chofer.activo ? 'success' : 'danger'}
                  className='text-capitalize btn-top-action'
                  onClick={handleToggleEstado}>
                  <MDBIcon className='mr-2' icon='power-off' size='lg' />
                  {chofer && chofer.activo ? 'Activo' : 'Desactivado'}
               </MDBBtn>
            </MDBCol>
         </MDBRow>

         <MDBRow>
            <MDBCol size='8'>
               {/* INFORMACION DE CHOFER */}
               <MDBCard className='mb-4'>
                  <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold'>
                     <MDBIcon size='lg' far icon='id-card' className='mr-3' />
                     Información del chofer
                  </div>
                  <MDBCardBody className='p-4'>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Nombre del chofer
                           </label>
                           <p>{chofer ? `${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}` : '-'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Tipo de chofer
                           </label>
                           <p>{chofer ? chofer.tipoChofer.nombre : '-'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Estado
                           </label>
                           <p>
                              {chofer ? (
                                 chofer.activo ? (
                                    <MDBBadge color='success px-4'>Activo</MDBBadge>
                                 ) : (
                                    <MDBBadge color='danger'>Desactivado</MDBBadge>
                                 )
                              ) : (
                                 'N/A'
                              )}
                           </p>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Foto
                           </label>
                           <MDBCol md='8' className='p-0'>
                              <img
                                 className='img-fluid'
                                 src={chofer ? process.env.REACT_APP_BASE_URL_ASSETS + chofer.imagen_url : ''}
                                 alt='Foto chofer'
                              />
                           </MDBCol>
                        </MDBCol>
                        {/* <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              UID
                           </label>
                           <p>{chofer ? chofer.uid : 'N/A'}</p>
                        </MDBCol> */}
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Correo electrónico
                           </label>
                           <p>{chofer ? chofer.email : '-'}</p>
                        </MDBCol>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Télefono
                           </label>
                           <p>{chofer ? chofer.telefono : '-'}</p>
                        </MDBCol>
                     </MDBRow>
                     {/* <MDBRow>
                        <MDBCol md='4'>
                           <label className='font-weight-medium' htmlFor='City'>
                              Foto
                           </label>
                           <p>{chofer ? chofer.imagen_url : 'N/A'}</p>
                        </MDBCol>
                     </MDBRow> */}
                  </MDBCardBody>
               </MDBCard>

               {/* INFORMACION ADICIONAL */}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('info-adicional')}>
                     <span>
                        <MDBIcon size='lg' icon='info-circle' className='mr-3' />
                        Información adicional
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'info-adicional' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='info-adicional' isOpen={collapseId}>
                     <MDBCardBody className='p-4'>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Renta de vehículo</label>
                              {/* TODO: no se que poner aqui */}
                              <p>{chofer && chofer.infoTaxista ? chofer.infoTaxista.montoRenta : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Día de la semana de corte</label>
                              <p>{chofer && chofer.infoTaxista ? getDiaCorte(chofer.infoTaxista.diaCorte) : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Monto de renta semanal</label>
                              <p>{chofer && chofer.infoTaxista ? chofer.infoTaxista.montoRenta : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Recargo por día atrasado</label>
                              <p>{chofer && chofer.infoTaxista ? chofer.infoTaxista.recargo : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow className='mt-4'>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Clave</label>
                              <p>
                                 {chofer && chofer.clave.length ? `${chofer.clave[0].prefijo + chofer.clave[0].codigo}` : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Fecha de nacimiento</label>
                              <p>{chofer && chofer.birthDate ? moment(chofer.birthDate).format('DD/MM/YYYY') : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>CURP</label>
                              <p>{chofer && chofer.CURP ? chofer.CURP : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>RFC</label>
                              <p>{chofer && chofer.RFC ? chofer.RFC : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Tipo de sangre</label>
                              <p>{chofer && chofer.tipoSangre ? chofer.tipoSangre : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Tipo de licencia</label>
                              <p>{chofer && chofer.tipoLicencia ? chofer.tipoLicencia.nombre : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>

                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Licencia</label>
                              <p>{chofer && chofer.licencia ? chofer.licencia : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Fecha de vencimiento</label>
                              <p>
                                 {chofer && chofer.expireDateLicencia
                                    ? moment(chofer.expireDateLicencia).format('DD/MM/YYYY')
                                    : 'N/A'}
                              </p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Número de cuenta bancaria</label>
                              <p>{chofer && chofer.cuentas.length ? chofer.cuentas[0].numeroCuenta : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Banco</label>
                              <p>{chofer && chofer.cuentas.length ? chofer.cuentas[0].banco.banco : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>CLABE</label>
                              <p>{chofer && chofer.cuentas.length ? chofer.cuentas[0].CLABE : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow className='mt-4'>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Calle de domicilio</label>
                              <p>{chofer && chofer.direccion.calle ? chofer.direccion.calle : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Número de casa</label>
                              <p>{chofer && chofer.direccion.numero ? chofer.direccion.numero : 'N/A'}</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Colonia</label>
                              <p>{chofer && chofer.direccion.colonia ? chofer.direccion.colonia : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                        <MDBRow>
                           <MDBCol md='4'>
                              <label className='font-weight-medium'>Código Postal</label>
                              <p>{chofer && chofer.direccion.cp ? chofer.direccion.cp : 'N/A'}</p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>

               {/* DETALLES DE VEHICULO */}
               <MDBCard className='mb-4'>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('detalles-vehiculo')}>
                     <span>
                        <MDBIcon size='lg' icon='car-alt' className='mr-3' />
                        Detalles de vehículo
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'detalles-vehiculo' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='detalles-vehiculo' isOpen={collapseId}>
                     <MDBCardBody className='p-4'>
                        <MDBRow className='mb-2'>
                           <Link to={`/r/choferes/${choferId}/editar`}>
                              <MDBBtn color='success' className='text-capitalize btn-top-action ml-4'>
                                 <MDBIcon className='mr-2' icon='edit' size='lg' />
                                 Editar
                              </MDBBtn>
                           </Link>
                           {/* <MDBBtn color='success' className='text-capitalize btn-top-action ml-4' onClick={handleEditarVehiculo}>
                              <MDBIcon className='mr-2' icon='edit' size='lg' />
                              Editar
                           </MDBBtn> */}
                        </MDBRow>
                        <BootstrapTable
                           bootstrap4
                           condensed
                           classes='vehiculos-table'
                           keyField='idVehiculo'
                           data={vehiculos}
                           columns={columns}
                           hover
                           bordered={false}
                           noDataIndication='No se encontraron registros.'
                           rowClasses={'font-weight-bold'}
                        />
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>

               {/* BALANCE */}
               <MDBCard>
                  <div
                     className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center'
                     onClick={() => handleToggleCard('balance')}>
                     <span>
                        <MDBIcon size='lg' icon='coins' className='mr-3' />
                        Balance
                     </span>
                     <span>
                        <MDBIcon size='lg' icon={collapseId === 'balance' ? 'minus' : 'plus'} />
                     </span>
                  </div>
                  <MDBCollapse id='balance' isOpen={collapseId}>
                     <MDBCardBody className='p-4'>
                        <p className='font-size-18'>Por semana</p>
                        <MDBRow>
                           <MDBCol md='4'>
                              <p>Total de viajes en efectivo:</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium'>--</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <p>Total de viajes con tarjeta:</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium text-success'>--</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <p>Renta semanal:</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium text-danger'> --</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <p>Comisión de tarjeta</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium text-danger'> --</p>
                           </MDBCol>
                           <MDBCol md='4'>
                              <p>Otros recargos</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium text-danger'> --</p>
                           </MDBCol>

                           <hr />

                           <MDBCol md='4'>
                              <p className='font-weight-medium font-size-18'>Total:</p>
                           </MDBCol>
                           <MDBCol md='5'>
                              <p className='font-weight-medium font-size-18 text-danger'> --</p>
                           </MDBCol>
                        </MDBRow>
                     </MDBCardBody>
                  </MDBCollapse>
               </MDBCard>
            </MDBCol>

            {/* ESTADISTICAS */}
            <MDBCol md='4'>
               <MDBCard className='mb-4'>
                  <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold d-flex justify-content-between'>
                     <span>
                        <MDBIcon size='lg' icon='chart-pie' className='mr-3' />
                        Estadisticas
                     </span>
                     <Link className='cursor-pointer text-white' to={`/r/choferes/${choferId}/estadisticas`}>
                        Ver más
                     </Link>
                  </div>
                  <MDBCardBody className='p-3'>
                     <p className='font-weight-medium font-size-18'>Hoy</p>
                     <h5 className='mb-0'>{moment().format('LL')}</h5>
                  </MDBCardBody>
               </MDBCard>

               {/* HORAS DE CONEXION */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-info' icon='power-off' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Horas de conexión</p>
                           <h3 className='font-weight-medium mb-0'>
                              {estadisticas.horasConexion ? estadisticas.horasConexion : '--:--:--'}
                           </h3>
                        </MDBMedia>
                     </MDBMedia>
                  </MDBCardBody>
               </MDBCard>

               {/* BALANCE DE VIAJES */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon
                              style={{ width: '94px' }}
                              className='card-icon bg-success text-center'
                              icon='dollar-sign'
                              size='3x'
                           />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Balance de viajes</p>
                           <h3 className='font-weight-medium mb-0'>{estadisticas.viajes.totalPedidos}</h3>
                        </MDBMedia>
                     </MDBMedia>
                  </MDBCardBody>
               </MDBCard>

               {/* SOLICITUDES */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-warning' icon='envelope' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Solicitudes</p>
                           <h3 className='font-weight-medium mb-0'>
                              {estadisticas.peticiones ? estadisticas.peticiones.total : 'N/A'}
                           </h3>
                        </MDBMedia>
                     </MDBMedia>

                     <MDBRow className='mt-4'>
                        <MDBCol className='font-weight-medium' md='9'>
                           Rechazadas por chofer:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.peticiones ? estadisticas.peticiones.rechazadas : 'N/A'}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Canceladas por cliente:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.peticiones ? estadisticas.peticiones.ignoradas : 'N/A'}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Aceptadas por chofer:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.peticiones ? estadisticas.peticiones.aceptadas : 'N/A'}
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>

               {/* VIAJES */}
               <MDBCard className='mb-4'>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-primary' icon='route' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Viajes</p>
                           <h3 className='font-weight-medium mb-0'>{estadisticas.viajes.totalPedidos}</h3>
                        </MDBMedia>
                     </MDBMedia>

                     <MDBRow className='mt-4'>
                        <MDBCol className='font-weight-medium' md='9'>
                           Viajes finalizados:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.viajes.finalizados}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Pagados con tarjeta:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.viajes.endCard}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Pagados con efectivo:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.viajes.endCash}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Pagados con monedero:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.viajes.endMonedero}
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Odómetro:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           {estadisticas.viajes.totalOdometro}
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>

               {/* TODO: Faltan todos los datos desglosados por estatus del viaje */}
               {/* CANCELACIONES */}
               <MDBCard>
                  <MDBCardBody>
                     <MDBMedia>
                        <MDBMedia className='mr-4'>
                           <MDBIcon className='card-icon bg-danger' icon='ban' size='3x' />
                        </MDBMedia>
                        <MDBMedia middle body>
                           <p>Cancelaciones</p>
                           <h3 className='font-weight-medium mb-0'>{estadisticas.cancelaciones.viajesCancelados}</h3>
                        </MDBMedia>
                     </MDBMedia>

                     <p className='text-muted font-weight-medium mt-4 mb-0'>Por clientes</p>
                     <hr className='mt-0' />
                     <MDBRow>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer en camino:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer a punto de llegar:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Llegó con el cliente:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                     </MDBRow>

                     <p className='text-muted font-weight-medium mt-2 mb-0'>Por chofer</p>
                     <hr className='mt-0' />
                     <MDBRow>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer en camino:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer a punto de llegar:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Llegó con el cliente:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                     </MDBRow>

                     <p className='text-muted font-weight-medium mt-2 mb-0'>Por administrador</p>
                     <hr className='mt-0' />
                     <MDBRow>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer en camino:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Chofer a punto de llegar:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Llegó con el cliente:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Viaje iniciado:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                        <MDBCol className='font-weight-medium' md='9'>
                           Viaje a punto de terminar:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           N/A
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
         </MDBRow>
      </section>
   );
};

export default ChoferDetalles;
