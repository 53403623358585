import React from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdbreact';
import ZonasLista from 'components/zonas/ZonasLista';
import Can from 'components/shared/Can';

export interface ZonasPageProps {}

const ZonasPage: React.FC<ZonasPageProps> = () => {
   return (
      <section id='zonas'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar zonas</h3>
               </header>
            </MDBCol>
            <Can I='create' a='Zona'>
               {() => (
                  <MDBCol className='text-right'>
                     <Link to='/r/zonas/nueva'>
                        <MDBBtn color='warning' className='text-capitalize btn-top-action'>
                           <MDBIcon className='mr-3' icon='plus' size='lg' />
                           Nueva Zona
                        </MDBBtn>
                     </Link>
                  </MDBCol>
               )}
            </Can>
         </MDBRow>

         {/* TABLA DE ZONAS */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <ZonasLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ZonasPage;
