import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import SaldosStats from 'components/saldos/SaldosStats';
import SaldosLista from 'components/saldos/SaldosLista';

export interface SaldosPageProps {}

const SaldosPage: React.FC<SaldosPageProps> = () => {
   return (
      <section id='saldos'>
         <MDBRow className='mb-2 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar saldos</h3>
               </header>
            </MDBCol>
         </MDBRow>

         <SaldosStats />

         {/* TABLA DE GRUPOS */}
         <MDBCard>
            <MDBCardBody className='p-3'>
               <h4 className='mb-4'>Pago a choferes</h4>
               <SaldosLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default SaldosPage;
