import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBMedia } from 'mdbreact';
import disconnectedIcon from 'assets/images/bolt-slash-solid-white.svg';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import './ChoferesStats.css';

interface Estadisticas {
   activos: string;
   inactivos: string;
   conectados: string;
   desconectados: string;
}

export interface ChoferesStatsProps {}

const ChoferesStats: React.FC<ChoferesStatsProps> = () => {
   const [estadisticas, setEstadisticas] = useState<Estadisticas>({
      activos: '-',
      inactivos: '-',
      conectados: '-',
      desconectados: '-'
   });

   useEffect(() => {
      const fetchEstadisticas = async () => {
         try {
            const estadisticasData: any = await http.get(`choferes/estadisticas`);
            setEstadisticas(estadisticasData);
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>Ha ocurrido un error al obtener las estadisticas, intente de nuevo.</ToastMessage>
               );
            }
         }
      };

      fetchEstadisticas();
      // eslint-disable-next-line
   }, []);

   return (
      <MDBRow className='mb-4'>
         <MDBCol md='3' className='mb-3 mb-md-0'>
            <MDBCard>
               <MDBCardBody>
                  <MDBMedia>
                     <MDBMedia className='mr-4'>
                        <MDBIcon className='card-icon bg-success-light' icon='route' size='3x' />
                     </MDBMedia>
                     <MDBMedia middle body>
                        <p>Activos</p>
                        <h3 className='font-weight-medium mb-0'>{estadisticas.activos}</h3>
                     </MDBMedia>
                  </MDBMedia>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>
         <MDBCol md='3' className='mb-3 mb-md-0'>
            <MDBCard>
               <MDBCardBody>
                  <MDBMedia>
                     <MDBMedia className='mr-4'>
                        <MDBIcon className='card-icon bg-danger' icon='minus-circle' size='3x' />
                     </MDBMedia>
                     <MDBMedia middle body>
                        <p>Inactivos</p>
                        <h3 className='font-weight-medium mb-0'>{estadisticas.inactivos}</h3>
                     </MDBMedia>
                  </MDBMedia>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>
         <MDBCol md='3' className='mb-3 mb-md-0'>
            <MDBCard>
               <MDBCardBody>
                  <MDBMedia>
                     <MDBMedia className='mr-4'>
                        <MDBIcon className='card-icon bg-info' icon='power-off' size='3x' />
                     </MDBMedia>
                     <MDBMedia middle body>
                        <p>Conectados</p>
                        <h3 className='font-weight-medium mb-0'>{estadisticas.conectados}</h3>
                     </MDBMedia>
                  </MDBMedia>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>
         <MDBCol md='3' className='mb-3 mb-md-0'>
            <MDBCard>
               <MDBCardBody>
                  <MDBMedia>
                     <MDBMedia className='mr-4'>
                        <div className='card-icon-svg bg-warning'>
                           <img className='icon-desconectado' src={disconnectedIcon} alt='' />
                        </div>
                     </MDBMedia>
                     <MDBMedia middle body>
                        <p>Desconectados</p>
                        <h3 className='font-weight-medium mb-0'>{estadisticas.desconectados}</h3>
                     </MDBMedia>
                  </MDBMedia>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>
      </MDBRow>
   );
};

export default React.memo(ChoferesStats);
