import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import moment from 'moment';
// Mis Types
import { Chofer } from 'models/Choferes';
import { Option } from 'typings/General';
import { getDiaCorte } from 'utils';

export interface ResumenProps {
   chofer: Chofer;
   hasRentaSemanal: boolean;
   tiposLicencia: Option[];
   marcasVehiculos: Option[];
   modelosVehiculos: Option[];
   onBackBtnClick: () => void;
   onFormSubmit: () => void;
}

const Resumen: React.FC<ResumenProps> = ({
   chofer,
   hasRentaSemanal,
   tiposLicencia,
   marcasVehiculos,
   modelosVehiculos,
   onBackBtnClick,
   onFormSubmit
}) => {
   const getTipoLicencia = (id: number) => {
      const licencia: Option = tiposLicencia.find(licencia => licencia.value === id) as Option;
      return licencia.label;
   };

   const getMarcaVehiculo = (id: number) => {
      const marca: Option = marcasVehiculos.find(marca => marca.value === id) as Option;
      return marca.label;
   };

   const getModeloVehiculo = (id: number) => {
      const modelo: Option = modelosVehiculos.find(modelo => modelo.value === id) as Option;
      return modelo.label;
   };

   return (
      <Fragment>
         <p className='text-muted font-size-18'>Tipo de chofer</p>

         <MDBRow className='mb-3'>
            <MDBCol md='4'>
               <label>Tipo de chofer</label>
               <p className='font-weight-medium'>Taxista</p>
            </MDBCol>
         </MDBRow>

         <hr />

         <p className='text-muted mt-4 font-size-18'>Información de usuario</p>
         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Nombres</label>
               <p className='font-weight-medium'>{chofer.nombres}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Apellidos</label>
               <p className='font-weight-medium'>{`${chofer.primerApellido} ${chofer.segundoApellido}`}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Correo electrónico</label>
               <p className='font-weight-medium'>{chofer.email}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Teléfono</label>
               <p className='font-weight-medium'>{chofer.telefono}</p>
            </MDBCol>
         </MDBRow>

         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Foto de perfil</label>
               {chofer.imgPerfil && typeof chofer.imgPerfil === 'string' ? (
                  <MDBCol md='8' className='p-0'>
                     <img className='img-fluid' src={chofer.imgPerfil} alt='Foto chofer' />
                  </MDBCol>
               ) : (
                  <p className='font-weight-medium'>
                     {chofer.imgPerfil && typeof chofer.imgPerfil === 'object' ? chofer.imgPerfil.name : ''}
                  </p>
               )}
            </MDBCol>
         </MDBRow>

         {hasRentaSemanal && (
            <Fragment>
               <hr />

               <p className='text-muted mt-4 font-size-18'>Renta de vehículo</p>
               <MDBRow className='mb-4'>
                  <MDBCol md='3'>
                     <label>Renta de vehículo</label>
                     <p className='font-weight-medium'>Sí</p>
                  </MDBCol>
                  <MDBCol md='3'>
                     <label>Día de la semana de corte</label>
                     <p className='font-weight-medium'>{getDiaCorte(chofer.diaCorte as number)}</p>
                  </MDBCol>
                  <MDBCol md='3'>
                     <label>Monto de renta semanal</label>
                     <p className='font-weight-medium'>$ {chofer.montoRenta}</p>
                  </MDBCol>
                  <MDBCol md='3'>
                     <label>Recargo por dia atrasado</label>
                     <p className='font-weight-medium'>$ {chofer.recargo}</p>
                  </MDBCol>
               </MDBRow>
            </Fragment>
         )}

         <hr />

         <p className='text-muted mt-4 font-size-18'>Información de chofer</p>
         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Clave</label>
               <p className='font-weight-medium'>{chofer.prefijoClave ? `${chofer.prefijoClave + chofer.codigoClave}` : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Fecha de nacimiento</label>
               <p className='font-weight-medium'>{chofer.birthDate ? moment(chofer.birthDate).format('DD/MM/YYYY') : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>CURP</label>
               <p className='font-weight-medium'>{chofer.CURP ? chofer.CURP : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>RFC</label>
               <p className='font-weight-medium'>{chofer.RFC ? chofer.RFC : 'N/A'}</p>
            </MDBCol>
         </MDBRow>

         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Tipo de sangre</label>
               <p className='font-weight-medium'>{chofer.tipoSangre ? chofer.tipoSangre : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Tipo de licencia</label>
               <p className='font-weight-medium'>{chofer.idTipoLicencia ? getTipoLicencia(chofer.idTipoLicencia) : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Licencia</label>
               <p className='font-weight-medium'>{chofer.licencia ? chofer.licencia : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Fecha de vencimiento</label>
               <p className='font-weight-medium'>
                  {chofer.expireDateLicencia ? moment(chofer.expireDateLicencia).format('DD/MM/YYYY') : 'N/A'}
               </p>
            </MDBCol>
         </MDBRow>

         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Número de cuenta bancaria</label>
               <p className='font-weight-medium'>{chofer.numeroCuenta ? chofer.numeroCuenta : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Banco</label>
               <p className='font-weight-medium'>{chofer.idBanco ? chofer.idBanco : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>CLABE</label>
               <p className='font-weight-medium'>{chofer.CLABE ? chofer.CLABE : 'N/A'}</p>
            </MDBCol>
         </MDBRow>

         <hr />

         <p className='text-muted mt-4 font-size-18'>Información de domicilio</p>
         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Calle</label>
               <p className='font-weight-medium'>{chofer.calle ? chofer.calle : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Número de casa</label>
               <p className='font-weight-medium'>{chofer.numero ? chofer.numero : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Colonia</label>
               <p className='font-weight-medium'>{chofer.colonia ? chofer.colonia : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Código postal</label>
               <p className='font-weight-medium'>{chofer.cp ? chofer.cp : 'N/A'}</p>
            </MDBCol>
         </MDBRow>

         <hr />

         <p className='text-muted mt-4 font-size-18'>Información de vehículo</p>
         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Número de unidad</label>
               <p className='font-weight-medium'>{chofer.numeroVehiculo}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Placas</label>
               <p className='font-weight-medium'>{chofer.placas}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Marca del vehículo</label>
               <p className='font-weight-medium'>{getMarcaVehiculo(chofer.idMarca as number)}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label>Modelo del vehículo</label>
               <p className='font-weight-medium'>{getModeloVehiculo(chofer.idModelo as number)}</p>
            </MDBCol>
         </MDBRow>

         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label>Año del vehículo</label>
               <p className='font-weight-medium'>{chofer.vehicleYear}</p>
            </MDBCol>
         </MDBRow>

         <MDBRow className='mt-5 justify-content-center'>
            <MDBCol md='4'>
               <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                  Atrás
               </MDBBtn>
            </MDBCol>
            <MDBCol md='4'>
               <MDBBtn color='default' className='font-weight-medium mx-0 px-5' block onClick={onFormSubmit}>
                  Terminar
               </MDBBtn>
            </MDBCol>
         </MDBRow>
      </Fragment>
   );
};

export default Resumen;
