import React, { Fragment } from 'react';
interface TabContainerProps {
   children?: React.ReactNode;
}

const TabContainer: React.FC<TabContainerProps> = ({ children }) => {
   return <Fragment>{children}</Fragment>;
};

export default TabContainer;
