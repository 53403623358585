import React, { Fragment, Dispatch, forwardRef } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { Map, TileLayer, Polygon } from 'react-leaflet';

interface ZonaOperacionProps {
   map: any;
   onAddPolygonPoint: (event: React.MouseEvent) => void;
   onResetPolygon: () => void;
   onBackBtnClick: () => void;
   onCheckStep: Dispatch<React.SetStateAction<number>>;
}

const ZonaOperacion: React.FC<ZonaOperacionProps> = (
   { map, onCheckStep, onAddPolygonPoint, onResetPolygon, onBackBtnClick },
   ref
) => {
   return (
      <Fragment>
         <p className='text-muted mb-1 font-size-18'>Información básica</p>
         <p className='mb-0'>
            Para crear la zona de operación haga click en el mapa para poner un marcador y rodee con marcadores el area que será
            la zona de operación
         </p>
         <MDBBtn color='danger' className='text-capitalize btn-top-action mb-3' onClick={onResetPolygon}>
            <MDBIcon className='mr-2' icon='eraser' size='lg' />
            Limpiar Mapa
         </MDBBtn>
         {/* MAPA */}
         <Map ref={ref} center={map.position} zoom={map.zoom} onClick={onAddPolygonPoint} animate>
            <TileLayer
               attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Polygon positions={map.polygonPoints} />
         </Map>

         <MDBRow className='mt-5 justify-content-center'>
            <MDBCol md='4'>
               <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                  Atrás
               </MDBBtn>
            </MDBCol>
            <MDBCol md='4'>
               <MDBBtn color='default' className='font-weight-medium mx-0 px-5' block onClick={onCheckStep}>
                  Siguiente
               </MDBBtn>
            </MDBCol>
         </MDBRow>
      </Fragment>
   );
};

export default forwardRef(ZonaOperacion);
