import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import moment from 'moment';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import { getCurrentUser } from 'services/authentication.service';

interface ViajesStatsResumen {
   viajes: [
      {
         realizados: number;
         cancelados: number;
         canceladosCliente: number;
         canceladosChofer: number;
      }
   ];
   peticiones: [
      {
         peticionesClientes: number;
         ignoradas: number;
         rechazadas: number;
         sinChofer: number;
      }
   ];
   ingresos: [
      {
         total: string;
         efectivo: string;
         tarjeta: string;
         odometro: string;
      }
   ];
}

export interface ViajesStatsGeneralesProps {}

const ViajesStatsGenerales: React.FC<ViajesStatsGeneralesProps> = () => {
   const user = getCurrentUser();
   const [zona, setZona] = useState<number>(
      user.zona ? user.zona : process.env.REACT_APP_ZONA_DEFAULT ? parseInt(process.env.REACT_APP_ZONA_DEFAULT) : null
   );
   const [estadisticas, setEstadisticas] = useState<ViajesStatsResumen>();

   // Obtener estadisticas generales de viajes
   useEffect(() => {
      const fetchViajesStats = async () => {
         try {
            const params = {
               ...(zona ? { idZona: zona } : { idZona: user.zona })
            };
            const estadisticasData: ViajesStatsResumen = await http.get('viajes/estadisticas', { params });
            setEstadisticas(estadisticasData);
         } catch (error) {
            if ((error.status && error.status !== 500) || error.type) {
               toast.error(
                  <ToastMessage type={'error'}>Ocurrió un error al cargar las estadísticas, recargue la página.</ToastMessage>
               );
            }
         }
      };

      fetchViajesStats();
      // eslint-disable-next-line
   }, []);

   return (
      <MDBCard>
         <div className='card-header card-header-shadow bg-dark-orange text-white font-weight-bold mb-4 d-flex justify-content-between'>
            <span>
               <MDBIcon size='lg' icon='chart-pie' className='mr-3' />
               Estadisticas - {moment().format('LL')}
            </span>
            <Link className='cursor-pointer text-white' to={`/r/viajes/estadisticas`}>
               Ver más
            </Link>
         </div>
         <MDBCardBody className='px-3 pb-3 pt-0'>
            <MDBRow>
               <MDBCol className='px-4' md='4' style={{ borderRight: '1px solid #E0E0E0' }}>
                  <p className='text-muted font-weight-medium font-size-18'>
                     <MDBIcon className='mr-3' icon='route' size='lg' />
                     Viajes
                  </p>
                  <MDBRow>
                     <MDBCol className='font-weight-medium' md='9'>
                        Realizados:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.viajes[0].realizados : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Cancelados:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.viajes[0].cancelados : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Cancelados por cliente:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.viajes[0].canceladosCliente : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Cancelados por admin:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.viajes[0].canceladosChofer : 'N/A'}
                     </MDBCol>
                  </MDBRow>
               </MDBCol>
               <MDBCol className='px-4' md='4' style={{ borderRight: '1px solid #E0E0E0' }}>
                  <p className='text-muted font-weight-medium font-size-18'>
                     <MDBIcon className='mr-3' far icon='envelope' size='lg' />
                     Solicitudes
                  </p>
                  <MDBRow>
                     <MDBCol className='font-weight-medium' md='9'>
                        Realizadas por clientes:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.peticiones[0].peticionesClientes : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Sin aceptar por choferes:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.peticiones[0].ignoradas : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Rechazadas por choferes:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.peticiones[0].rechazadas : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Sin chofer disponible:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        {estadisticas ? estadisticas.peticiones[0].sinChofer : 'N/A'}
                     </MDBCol>
                  </MDBRow>
               </MDBCol>
               <MDBCol className='px-4' md='4'>
                  <p className='text-muted font-weight-medium font-size-18'>
                     <MDBIcon className='mr-3' icon='dollar-sign' size='lg' />
                     Ingresos
                  </p>
                  <MDBRow>
                     <MDBCol className='font-weight-medium' md='9'>
                        Total:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        ${estadisticas ? parseFloat(estadisticas.ingresos[0].total).toFixed(2) : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        En efectivo:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        ${estadisticas ? parseFloat(estadisticas.ingresos[0].efectivo).toFixed(2) : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Por tarjeta:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        ${estadisticas ? parseFloat(estadisticas.ingresos[0].tarjeta).toFixed(2) : 'N/A'}
                     </MDBCol>
                     <MDBCol className='font-weight-medium' md='9'>
                        Odómetro:
                     </MDBCol>
                     <MDBCol className='mb-3 text-right' md='3'>
                        ${estadisticas ? parseFloat(estadisticas.ingresos[0].odometro).toFixed(2) : 'N/A'}
                     </MDBCol>
                  </MDBRow>
               </MDBCol>
            </MDBRow>
         </MDBCardBody>
      </MDBCard>
   );
};

export default ViajesStatsGenerales;
