import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBBadge, MDBInput } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import Rating from 'react-rating';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';

export interface ClienteQuejasListaProps {
   idCliente: number;
}

const ClienteQuejasLista: React.FC<ClienteQuejasListaProps> = ({ idCliente }) => {
   const [reportes, setReportes] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);

   const ratingFormatter = (rating: number) => {
      return <Rating initialRating={rating} readonly emptySymbol='far fa-star fa-lg mr-1' fullSymbol='fas fa-star fa-lg mr-1' />;
   };
   const estatusBadgeFormatter = estatus => {
      switch (estatus) {
         case 1:
            return <MDBBadge color='danger px-4'>Pendiente</MDBBadge>;
         case 2:
            return <MDBBadge color='warning px-4'>En seguimiento</MDBBadge>;
         case 3:
            return <MDBBadge color='success px-4'>Finalizado</MDBBadge>;
      }
   };
   const optionsFormatter = (cell, row) => {
      return (
         <Link
            className='btn btn-primary btn-sm mr-2 detalles-font-size text-capitalize'
            to={`/r/quejas/${row.idReporte}/detalles`}>
            Ver más
         </Link>
      );
   };
   const columns = [
      {
         dataField: 'idReporte',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'calViaje',
         text: 'Calificación de viaje',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: ratingFormatter
      },
      {
         dataField: 'calCliente',
         text: 'Calificación del cliente',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: ratingFormatter
      },
      {
         dataField: 'estatus',
         text: 'Estado',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: estatusBadgeFormatter
      },
      {
         dataField: 'options',
         text: '',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: optionsFormatter
      }
   ];

   useEffect(() => {
      const fetchQuejas = async () => {
         try {
            const params = {
               order: 'desc',
               idCliente
            };
            const { rows } = await http.get(`quejas`, { params });
            setReportes(mapReportesToViewModel(rows));
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener las quejas, intente de nuevo.</ToastMessage>
            );
         }
      };

      fetchQuejas();
   }, [debouncedSearch, idCliente]);

   const mapReportesToViewModel = (reportes: any[]) => {
      return reportes.map(reporte => {
         return {
            idReporte: reporte.idQueja,
            calChofer: reporte.viaje.puntosChofer ? parseFloat(reporte.viaje.puntosChofer.rate) : 0,
            calCliente: reporte.viaje.puntosCliente ? parseFloat(reporte.viaje.puntosCliente.rate) : 0,
            estatus: reporte.estadoQueja.idEstatusQueja
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   return (
      <Fragment>
         <MDBRow className='mb-2'>
            <MDBCol md='8'>
               <MDBInput
                  className='m-0'
                  label='Buscar ID'
                  outline
                  icon='search'
                  iconSize='lg'
                  onChange={handleSearchChange}
                  value={search}
               />
            </MDBCol>
         </MDBRow>
         <BootstrapTable
            bootstrap4
            condensed
            classes='reportes-table'
            keyField='idReporte'
            data={reportes}
            columns={columns}
            hover
            bordered={false}
            noDataIndication='No se encontraron registros.'
            rowClasses={'font-weight-bold'}
         />
      </Fragment>
   );
};

export default React.memo(ClienteQuejasLista);
