import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
// import moment from 'moment';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import Label from 'components/shared/Label';
import useInterval from 'hooks/useInterval';

interface Horas {
   horas: string;
}

interface Estadisticas {
   viajes: {
      endCash: string;
      endCard: string;
      canceledCash: string;
      canceledCard: string;
      totalEfectivo: string;
      totalTarjeta: string;
      totalMonedero: string;
      total: string;
      adeudos: string;
   };
   choferes: {
      activos: string;
      deshabilitados: string;
      sinConexion: string;
      esperandoViaje: string;
      enCamino: string;
      esperandoCliente: string;
      enViaje: string;
      terminandoViaje: string;
      odometro: string;
   };
   peticiones: number;
   horasConexion: Horas[];
}

export interface MonitorStatsProps {
   idZona: number;
}

const MonitorStats: React.FC<MonitorStatsProps> = ({ idZona }) => {
   const [estadisticas, setEstadisticas] = useState<Estadisticas>({
      viajes: {
         endCash: '-',
         endCard: '-',
         canceledCash: '-',
         canceledCard: '-',
         totalEfectivo: '-',
         totalTarjeta: '-',
         totalMonedero: '-',
         total: '-',
         adeudos: '-'
      },
      choferes: {
         activos: '-',
         deshabilitados: '-',
         sinConexion: '-',
         esperandoViaje: '-',
         enCamino: '-',
         esperandoCliente: '-',
         enViaje: '-',
         terminandoViaje: '-',
         odometro: '-'
      },
      peticiones: 0,
      horasConexion: [
         {
            horas: '--:--:--'
         }
      ]
   });

   useEffect(() => {
      fetchEstadisticas();
      // eslint-disable-next-line
   }, [idZona]);

   const fetchEstadisticas = async () => {
      try {
         const params = {
            idZona
         };
         const estadisticasData: any = await http.get(`monitor/estadisticas`, { params });
         setEstadisticas(estadisticasData);
      } catch (error) {
         if ((error.status && error.status !== 500) || error.type) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener las estadisticas, intente de nuevo.</ToastMessage>
            );
         }
      }
   };

   // Refresh Estadisticas cada 5min
   useInterval(() => {
      fetchEstadisticas();
   }, 60000 * 5);

   return (
      <MDBRow className='mb-3'>
         {/* VIAJES */}
         <MDBCol md='3'>
            <Label className='bg-dark-orange mb-3'>
               <MDBIcon icon='route' size='lg' className='mr-2' />
               Viajes
            </Label>

            <MDBCard className='stats-card'>
               <MDBCardBody className='pb-0'>
                  <p className='font-weight-medium'>Peticiones realizadas</p>
                  <p>{estadisticas.peticiones} peticiones</p>
                  <p className='font-weight-medium'>Viajes terminados</p>
                  <MDBRow className='mb-3'>
                     <MDBCol md='6'>{estadisticas.viajes.endCard} tarjeta</MDBCol>
                     <MDBCol md='6'>{estadisticas.viajes.endCash} efectivo</MDBCol>
                  </MDBRow>

                  <p className='font-weight-medium'>Viajes cancelados</p>
                  <MDBRow>
                     <MDBCol md='6'>{estadisticas.viajes.canceledCard} tarjeta</MDBCol>
                     <MDBCol md='6'>{estadisticas.viajes.canceledCash} efectivo</MDBCol>
                  </MDBRow>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>

         {/* CHOFERES */}
         <MDBCol md='3'>
            <Label className='bg-warning mb-3'>
               <MDBIcon icon='car-alt' size='lg' className='mr-2' />
               Choferes
            </Label>

            <MDBCard>
               <MDBCardBody className='pb-0'>
                  <dl className='row mb-0'>
                     <dt className='col-sm-9 font-weight-medium'>Activos</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.activos}</dd>
                     <dt className='col-sm-9 font-weight-medium'>Sin conexión</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.sinConexion}</dd>
                     <dt className='col-sm-9 font-weight-medium'>Esperando viaje</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.esperandoViaje}</dd>
                     <dt className='col-sm-9 font-weight-medium'>En camino</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.enCamino}</dd>
                     <dt className='col-sm-9 font-weight-medium'>Esperando cliente</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.esperandoCliente}</dd>
                     <dt className='col-sm-9 font-weight-medium'>En viaje</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.enViaje}</dd>
                     <dt className='col-sm-9 font-weight-medium'>Terminando viaje</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.terminandoViaje}</dd>
                     <dt className='col-sm-9 font-weight-medium'>Odómetro</dt>
                     <dd className='col-sm-3 text-right'>{estadisticas.choferes.odometro}</dd>
                  </dl>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>

         {/* GANANCIAS */}
         <MDBCol md='3'>
            <Label className='bg-success-light mb-3'>
               <MDBIcon icon='dollar-sign' size='lg' className='mr-2' />
               Ganancias
            </Label>

            <MDBCard className='stats-card'>
               <MDBCardBody className='pb-0'>
                  <MDBRow>
                     <MDBCol md='6'>
                        <p className='font-weight-medium'>Total</p>
                        <p>$ {parseFloat(estadisticas.viajes.total).toFixed(2)}</p>
                     </MDBCol>
                     <MDBCol md='6'>
                        <p className='font-weight-medium'>Adeudos</p>
                        <p>$ {parseFloat(estadisticas.viajes.adeudos).toFixed(2)}</p>
                     </MDBCol>
                  </MDBRow>
                  <MDBRow>
                     <MDBCol md='6'>
                        <p className='font-weight-medium'>Pago con tarjeta</p>
                        <p>$ {parseFloat(estadisticas.viajes.totalTarjeta).toFixed(2)}</p>
                     </MDBCol>
                     <MDBCol md='6'>
                        <p className='font-weight-medium'>Pago con monedero</p>
                        <p>$ {parseFloat(estadisticas.viajes.totalMonedero).toFixed(2)}</p>
                     </MDBCol>
                  </MDBRow>
                  <MDBRow>
                     <MDBCol md='6'>
                        <p className='font-weight-medium'>Pago en efectivo</p>
                        <p className='mb-0'>$ {parseFloat(estadisticas.viajes.totalEfectivo).toFixed(2)}</p>
                     </MDBCol>
                  </MDBRow>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>

         {/* TIEMPO DE CONEXIÓN */}
         <MDBCol md='3'>
            <Label className='bg-info mb-3'>
               <MDBIcon icon='power-off' size='lg' className='mr-2' />
               Tiempo de conexión
            </Label>

            <MDBCard>
               <MDBCardBody>
                  <p className='font-weight-medium mb-1'>Horas de conexión</p>
                  <h4>{estadisticas.horasConexion[0].horas} Hrs</h4>
               </MDBCardBody>
            </MDBCard>
         </MDBCol>
      </MDBRow>
   );
};

export default React.memo(MonitorStats);
