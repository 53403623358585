import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import TabContainer from 'components/shared/TabContainer';
import Informacion from 'components/choferes/form/Informacion';
import DetallesAdicionales from 'components/choferes/form/DetallesAdicionales';
import AsignarVehiculo from 'components/choferes/form/AsignarVehiculo';
import Resumen from 'components/choferes/form/Resumen';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
// Mis Types
import { Chofer } from 'models/Choferes';
import { Option } from 'typings/General';
import { mapOptionsToViewModel, generateYears } from 'utils';

const InitialState: Chofer = {
   idTipoChofer: null,
   idZona: null,
   nombres: '',
   primerApellido: '',
   segundoApellido: '',
   email: '',
   telefono: '',
   password: '',
   imgPerfil: undefined,
   diaCorte: null,
   montoRenta: undefined,
   recargo: undefined,
   prefijoClave: '',
   codigoClave: '',
   birthDate: undefined,
   CURP: '',
   RFC: '',
   tipoSangre: '',
   idTipoLicencia: null,
   licencia: '',
   expireDateLicencia: undefined,
   numeroCuenta: '',
   idBanco: null,
   CLABE: '',
   calle: '',
   numero: '',
   colonia: '',
   cp: '',
   numeroVehiculo: '',
   placas: '',
   idMarca: null,
   idModelo: null,
   vehicleYear: null
};

export interface ChoferFormProps extends RouteComponentProps {}

const ChoferStepperContainer: React.FC<ChoferFormProps> = ({ match, history }) => {
   // FORM STATE
   const choferId = match.params['id'];
   const [chofer, setChofer] = useState<Chofer>({
      ...InitialState
   });
   const [choferUpdate, setChoferUpdate] = useState<Chofer>({
      ...InitialState
   });
   const [rentaSemanal, setHasRentaSemanal] = useState<boolean>(false);
   const [rentaSemanalUpdate, setHasRentaSemanalUpdate] = useState<boolean>(false);

   // TABS
   const [tabValue, setTabValue] = useState<number>(0);
   // SELECTS
   const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
   const [zonas, setZonas] = useState<any[]>([]);
   const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
   const diaCorteOptions: Option[] = [
      { value: 0, label: 'Lunes' },
      { value: 1, label: 'Martes' },
      { value: 2, label: 'Miercoles' },
      { value: 3, label: 'Jueves' },
      { value: 4, label: 'Viernes' },
      { value: 5, label: 'Sabado' },
      { value: 6, label: 'Domingo' }
   ];
   const [tipoLicenciaOptions, setTipoLicencia] = useState<Option[]>([]);
   const [bancosOptions, setBancosOptions] = useState<Option[]>([]);
   const [marcasVehiculosOptions, setMarcasVehiculos] = useState<Option[]>([]);
   const [modelosVehiculosOptions, setModelosVehiculos] = useState<Option[]>([]);
   const [anoVehiculosOptions, setAnoVehiculos] = useState<Option[]>([]);

   // Obtener datos para los Selects
   useEffect(() => {
      const fetchSelects = async () => {
         try {
            const { rows: tiposChofer }: any = await http.get('catalogos/TiposChofer');
            setTipoChoferOptions(mapOptionsToViewModel(tiposChofer));

            const params = {
               activo: true
            };
            const { rows: zonas }: any = await http.get('zonas', { params });
            setZonas(zonas);
            setZonasOptions(mapOptionsToViewModel(zonas));

            const { rows: tiposLicencia }: any = await http.get('catalogos/TiposLicencia');
            setTipoLicencia(mapOptionsToViewModel(tiposLicencia));

            const { rows: bancos }: any = await http.get('catalogos/Bancos');
            setBancosOptions(mapOptionsToViewModel(bancos));

            const { rows: marcas }: any = await http.get('catalogos/Marcas');
            setMarcasVehiculos(mapOptionsToViewModel(marcas));
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al cargar algunas opciones, intente de nuevo.</ToastMessage>
            );
         }
      };

      fetchSelects();
      setAnoVehiculos(generateYears());
   }, []);

   // Obtener datos para editar chofer
   useEffect(() => {
      const fetchChofer = async () => {
         try {
            const choferData: any = await http.get(`choferes/${choferId}`);
            populateChofer(choferData);
         } catch (error) {
            toast.error(
               <ToastMessage type={'error'}>Ha ocurrido un error al obtener los datos del chofer, intente de nuevo.</ToastMessage>
            );
         }
      };

      const populateChofer = chofer => {
         const choferMapped = {
            idTipoChofer: chofer.tipoChofer.idTipoChofer,
            idZona: chofer.zona.idZona,
            nombres: chofer.nombres,
            primerApellido: chofer.primerApellido,
            segundoApellido: chofer.segundoApellido,
            email: chofer.email,
            telefono: chofer.telefono,
            password: '',
            imgPerfil: process.env.REACT_APP_BASE_URL_ASSETS + chofer.imagen_url,
            diaCorte: chofer.infoTaxista ? chofer.infoTaxista.diaCorte : null,
            montoRenta: chofer.infoTaxista ? parseFloat(chofer.infoTaxista.montoRenta.toFixed(2)) : undefined,
            recargo: chofer.infoTaxista ? parseFloat(chofer.infoTaxista.recargo.toFixed(2)) : undefined,
            prefijoClave: chofer.zona.prefijoChofer ? chofer.zona.prefijoChofer : '',
            codigoClave: chofer.clave.length ? chofer.clave[0].codigo : '',
            birthDate: chofer.birthDate ? chofer.birthDate : undefined,
            CURP: chofer.CURP ? chofer.CURP : '',
            RFC: chofer.RFC ? chofer.RFC : '',
            tipoSangre: chofer.tipoSangre ? chofer.tipoSangre : '',
            idTipoLicencia: chofer.tipoLicencia ? chofer.tipoLicencia.idTipoLicencia : null,
            licencia: chofer.licencia ? chofer.licencia : '',
            expireDateLicencia: chofer.expireDateLicencia ? chofer.expireDateLicencia : undefined,
            numeroCuenta: chofer.cuentas.length && chofer.cuentas[0].numeroCuenta ? chofer.cuentas[0].numeroCuenta : '',
            idBanco: chofer.cuentas.length ? chofer.cuentas[0].banco.idBanco : null,
            CLABE: chofer.cuentas.length && chofer.cuentas[0].CLABE ? chofer.cuentas[0].CLABE : '',
            calle: chofer.direccion.calle ? chofer.direccion.calle : '',
            numero: chofer.direccion.numero ? chofer.direccion.numero : '',
            colonia: chofer.direccion.colonia ? chofer.direccion.colonia : '',
            cp: chofer.direccion.cp ? chofer.direccion.cp : '',
            numeroVehiculo: chofer.vehiculo[0].numeroVehiculo,
            placas: chofer.vehiculo[0].placas[0].placas,
            idMarca: chofer.vehiculo[0].marca.idMarca,
            idModelo: chofer.vehiculo[0].modelo.idModelo,
            vehicleYear: chofer.vehiculo[0].vehicleYear
         };
         setChofer(cloneDeep(choferMapped));
         setChoferUpdate(cloneDeep(choferMapped));

         if (!chofer.infoTaxista) {
            setHasRentaSemanal(false);
            setHasRentaSemanalUpdate(false);
         }
         if (chofer.vehiculo[0].marca.idMarca) {
            fetchModelosVehiculos(chofer.vehiculo[0].marca.idMarca);
         }
      };

      if (choferId) {
         fetchChofer();
      }
   }, [choferId]);

   const fetchModelosVehiculos = async (id: number) => {
      try {
         const { rows }: any = await http.get(`catalogos/${id}/Modelos`);
         setModelosVehiculos(mapOptionsToViewModel(rows));
      } catch (error) {
         toast.error(
            <ToastMessage type={'error'}>
               Ha ocurrido un error al obtener los modelos del vehiculo, intente de nuevo.
            </ToastMessage>
         );
      }
   };

   const handleSwitchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setHasRentaSemanal(input.checked);
   };

   const handleBackBtnClick = () => setTabValue(tabValue => tabValue - 1);

   const handleStepSubmit = values => {
      // Asignar el prefijo de la zona al chofer.
      if (tabValue === 0) {
         const foundZona = zonas.find(zona => zona.idZona === values.idZona);
         setChofer({
            ...chofer,
            ...values,
            prefijoClave: foundZona.prefijoChofer
         });
         setTabValue(tabValue => tabValue + 1);
         return;
      }
      setChofer({
         ...chofer,
         ...values
      });
      setTabValue(tabValue => tabValue + 1);
   };

   const handleFormSubmit = async () => {
      try {
         choferId ? await updateChofer() : await createChofer();
      } catch (error) {
         apiErrorHandler('Chofer', error);
      }
   };

   const updateChofer = async () => {
      const body = setFormDataPut(chofer);
      await http.put(`choferes/${choferId}`, body);
      const result = await Swal.fire({
         title: 'Datos actualizados',
         text: 'Los cambios han sido actualizados correctamente.',
         type: 'success',
         showCancelButton: false,
         confirmButtonText: 'Aceptar',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-info waves-effect waves-light text-capitalize'
         },
         buttonsStyling: false
      });
      if (result) {
         history.push(`/r/choferes`);
      }
   };

   const setFormDataPut = chofer => {
      const body = new FormData();
      for (const key of Object.keys(chofer)) {
         if ((choferUpdate[key] !== chofer[key] || key === 'prefijoClave') && key !== 'confirmPassword') {
            body.append(key, chofer[key]);
         }
      }
      if (rentaSemanal) {
         body.append('rentaSemanal', rentaSemanal.toString());
      } else {
         if (rentaSemanalUpdate !== rentaSemanal) {
            body.append('rentaSemanal', 'false');
         }
         body.delete('diaCorte');
         body.delete('montoRenta');
         body.delete('recargo');
      }
      return body;
   };

   const createChofer = async () => {
      const body = setFormDataPost(chofer);
      const newChofer: any = await http.post('choferes', body);
      const result = await Swal.fire({
         title: 'Chofer guardado!',
         text: 'El nuevo chofer ha sido guardado.',
         type: 'success',
         showCancelButton: true,
         confirmButtonText: 'Crear otro Chofer',
         cancelButtonText: 'Ver detalles',
         allowOutsideClick: false,
         customClass: {
            confirmButton: 'btn btn-info waves-effect waves-light text-capitalize',
            cancelButton: 'btn btn-info waves-effect waves-light text-capitalize ml-2'
         },
         buttonsStyling: false
      });
      if (result.value) {
         // Ir al paso 1
         setTabValue(0);
         // Reset form
         setChofer({
            ...InitialState
         });
         return;
      }
      history.push(`/r/choferes/${newChofer.idChofer}/detalles`);
   };

   const setFormDataPost = chofer => {
      const body = new FormData();
      for (const key of Object.keys(chofer)) {
         if (chofer[key] !== undefined && chofer[key] !== null && chofer[key] !== '' && key !== 'confirmPassword') {
            body.append(key, chofer[key]);
         }
      }
      if (chofer.idTipoChofer === 1 && rentaSemanal) {
         body.append('rentaSemanal', rentaSemanal.toString());
      }
      return body;
   };

   if (choferId && (!chofer.idTipoChofer || !zonasOptions.length)) {
      return null;
   }
   return (
      <section id='choferes'>
         <header className='mb-4'>
            <h3 className='mb-0'>
               <Link className='text-dark' to='/r/choferes'>
                  <MDBIcon className='mr-3' icon='arrow-left' />
               </Link>
               {choferId ? 'Editar Chofer' : 'Nuevo Chofer'}
            </h3>
         </header>
         <MDBCard>
            <MDBCardBody>
               <Tabs
                  classes={{
                     indicator: '#EB6C40'
                  }}
                  scrollButtons='auto'
                  variant='fullWidth'
                  value={tabValue}>
                  <Tab label='1. Información de chofer' />
                  <Tab label='2. Detalles adicionales' />
                  <Tab label='3. Asignar vehículo' />
                  <Tab label='4. Resumen' />
               </Tabs>
               <div className='p-4'>
                  {tabValue === 0 && (
                     <TabContainer>
                        <Informacion
                           chofer={chofer}
                           isChoferEdit={choferId ? true : false}
                           tiposChofer={tipoChoferOptions}
                           zonas={zonasOptions}
                           onStepSubmit={handleStepSubmit}
                        />
                     </TabContainer>
                  )}
                  {tabValue === 1 && (
                     <TabContainer>
                        <DetallesAdicionales
                           chofer={chofer}
                           hasRentaSemanal={rentaSemanal}
                           diasCorte={diaCorteOptions}
                           tiposLicencia={tipoLicenciaOptions}
                           bancos={bancosOptions}
                           onSwitchChange={handleSwitchChange}
                           onBackBtnClick={handleBackBtnClick}
                           onStepSubmit={handleStepSubmit}
                        />
                     </TabContainer>
                  )}
                  {tabValue === 2 && (
                     <TabContainer>
                        <AsignarVehiculo
                           chofer={chofer}
                           marcas={marcasVehiculosOptions}
                           modelos={modelosVehiculosOptions}
                           anos={anoVehiculosOptions}
                           fetchModelosVehiculos={fetchModelosVehiculos}
                           onBackBtnClick={handleBackBtnClick}
                           onStepSubmit={handleStepSubmit}
                        />
                     </TabContainer>
                  )}
                  {tabValue === 3 && (
                     <TabContainer>
                        <Resumen
                           chofer={chofer}
                           hasRentaSemanal={rentaSemanal}
                           tiposLicencia={tipoLicenciaOptions}
                           marcasVehiculos={marcasVehiculosOptions}
                           modelosVehiculos={modelosVehiculosOptions}
                           onBackBtnClick={handleBackBtnClick}
                           onFormSubmit={handleFormSubmit}
                        />
                     </TabContainer>
                  )}
               </div>
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ChoferStepperContainer;
