import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
// Mis Componentes
import SelectInput from 'components/shared/SelectInput';
import ErrorFeedback from 'components/shared/ErrorFeedback';
// Mis Types
import { Chofer } from 'models/Choferes';
import { Option } from 'typings/General';

interface AsignarVehiculoProps {
   chofer: Chofer;
   marcas: Option[];
   modelos: Option[];
   anos: Option[];
   fetchModelosVehiculos: (id: number) => Promise<void>;
   onBackBtnClick: () => void;
   onStepSubmit: (values, actions) => void;
}

const AsignarVehiculo: React.FC<AsignarVehiculoProps> = ({
   chofer,
   marcas,
   modelos,
   anos,
   fetchModelosVehiculos,
   onBackBtnClick,
   onStepSubmit
}) => {
   const initialValues = {
      numeroVehiculo: chofer.numeroVehiculo,
      placas: chofer.placas,
      idMarca: chofer.idMarca,
      idModelo: chofer.idModelo,
      vehicleYear: chofer.vehicleYear
   };

   const asignarVehiculoSchema = Yup.object().shape({
      numeroVehiculo: Yup.string().required('Campo requerido'),
      placas: Yup.string().required('Campo requerido'),
      idMarca: Yup.number()
         .nullable()
         .required('Campo requerido'),
      idModelo: Yup.number()
         .nullable()
         .required('Campo requerido'),
      vehicleYear: Yup.number()
         .nullable()
         .required('Campo requerido')
   });

   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         validationSchema={asignarVehiculoSchema}
         onSubmit={onStepSubmit}
         render={({ setFieldValue }: FormikProps<any>) => (
            <Form>
               <p className='text-muted font-size-18'>Información de vehículo</p>

               <MDBRow className='mb-4'>
                  <MDBCol>
                     <Field
                        name='numeroVehiculo'
                        render={({ field }) => <MDBInput {...field} outline label='Número de unidad' type='text' />}
                     />
                     <ErrorMessage name='numeroVehiculo' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol>
                     <Field name='placas' render={({ field }) => <MDBInput {...field} outline label='Placas' type='text' />} />
                     <ErrorMessage name='placas' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol>
                     <Field
                        name='idMarca'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Marca de vehículo'
                              options={marcas}
                              setFieldValue={setFieldValue}
                              fetchRelatedFieldData={fetchModelosVehiculos}
                           />
                        )}
                     />
                     <ErrorMessage name='idMarca' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <MDBRow className='mb-4'>
                  <MDBCol md='4'>
                     <Field
                        name='idModelo'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Modelo del vehículo'
                              options={modelos}
                              setFieldValue={setFieldValue}
                           />
                        )}
                     />
                     <ErrorMessage name='idModelo' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='vehicleYear'
                        render={({ field }) => (
                           <SelectInput {...field} placeholder='Año del vehículo' options={anos} setFieldValue={setFieldValue} />
                        )}
                     />
                     <ErrorMessage name='vehicleYear' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                        Atrás
                     </MDBBtn>
                  </MDBCol>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default AsignarVehiculo;
