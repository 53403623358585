import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import { Formik, Field, FieldArray, ArrayHelpers, Form, ErrorMessage, FormikProps } from 'formik';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
// Mis Types
import Zona from 'models/Zonas';
import cloneDeep from 'lodash/cloneDeep';

const getTitle = (id: number) => {
   switch (id) {
      case 1:
         return 'Chofer Taxista';
      case 2:
         return 'Chofer Privado';
      case 3:
         return 'Chofer x Viajes';
   }
};

const validateField = value => {
   let error;
   if (!value && parseFloat(value) !== 0) {
      error = 'Campo requerido';
   } else if (!/^(?!0\d)\d*(\.\d{1,2})?$/.test(value)) {
      error = 'Ingrese un formato válido. Ej: 400, 400.00';
   }
   return error;
};

export interface ComisionesProps {
   zona: Zona;
   onBackBtnClick: () => void;
   onStepSubmit: (values, actions) => void;
}

const Comisiones: React.FC<ComisionesProps> = ({ zona, onBackBtnClick, onStepSubmit }) => {
   const initialValues = cloneDeep(zona);
   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         onSubmit={onStepSubmit}
         render={({ values: { comisiones } }: FormikProps<Zona>) => (
            <Form>
               <FieldArray
                  name='comisiones'
                  render={(arrayHelpers: ArrayHelpers) => (
                     <Fragment>
                        {comisiones.map((comision, index) => (
                           <Fragment key={comision.idTipoChofer}>
                              <p className='text-muted mt-4 mb-1 font-size-18'>{getTitle(comision.idTipoChofer as number)}</p>

                              {comision.idTipoChofer === 1 && (
                                 <Fragment>
                                    <MDBRow className='mb-3'>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.efectivo`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión efectivo (%)' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.efectivo`} component={ErrorFeedback} />
                                       </MDBCol>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.tarjeta`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión tarjeta (%)' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.tarjeta`} component={ErrorFeedback} />
                                       </MDBCol>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.odometro`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión odómetro' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.odometro`} component={ErrorFeedback} />
                                       </MDBCol>
                                    </MDBRow>
                                    <hr />
                                 </Fragment>
                              )}

                              {comision.idTipoChofer === 2 && (
                                 <Fragment>
                                    <MDBRow className='mb-3'>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.efectivo`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión efectivo (%)' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.efectivo`} component={ErrorFeedback} />
                                       </MDBCol>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.tarjeta`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión tarjeta (%)' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.tarjeta`} component={ErrorFeedback} />
                                       </MDBCol>
                                    </MDBRow>

                                    <hr />
                                 </Fragment>
                              )}

                              {comision.idTipoChofer === 3 && (
                                 <Fragment>
                                    <MDBRow className='mb-3'>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.costoViaje`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Costo por viaje' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.costoViaje`} component={ErrorFeedback} />
                                       </MDBCol>
                                       <MDBCol md='4'>
                                          <Field
                                             name={`comisiones.${index}.tarjeta`}
                                             validate={validateField}
                                             render={({ field }) => (
                                                <MDBInput {...field} outline label='Comisión tarjeta (%)' type='text' />
                                             )}
                                          />
                                          <ErrorMessage name={`comisiones.${index}.tarjeta`} component={ErrorFeedback} />
                                       </MDBCol>
                                    </MDBRow>
                                 </Fragment>
                              )}
                           </Fragment>
                        ))}
                     </Fragment>
                  )}
               />

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                        Atrás
                     </MDBBtn>
                  </MDBCol>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default Comisiones;
